import { Person, LibraryBooks, Logout, Apps } from "@mui/icons-material";
import { Dropdown, MenuButton, Box, Avatar, Menu, MenuItem, Typography } from "@mui/joy";
import { Link } from "react-router-dom";

import { useUser } from "../../providers/UserInfo";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { AuthSession } from "@aws-amplify/core/dist/esm/singleton/Auth/types";
import { useEffect, useState } from "react";

export default function LoginMenu(props: {
	color?: "primary" | "secondary";
	variant?: "solid" | "plain" | "outlined" | "soft";
}) {
	const user = useUser();
	const [impersonatedUserName, setImpersonatedUserName] = useState(sessionStorage.getItem("impersonatedUserName"));
	const [admin, setAdmin] = useState(false);

	useEffect(() => {
		fetchAuthSession()
			.then((x: AuthSession) => {
				//@ts-ignore
				if (x.tokens?.accessToken?.payload["cognito:groups"]?.includes("admins")) {
					setAdmin(true);
				}
			})
			.catch((err) => {
				console.error("Erro ao recuperar token", err);
			});
		const imp = sessionStorage.getItem("impersonatedUserName");
		if (imp) setImpersonatedUserName(imp);
	}, []);

	async function handleSignOut() {
		sessionStorage.removeItem("impersonatedUser");
		sessionStorage.removeItem("impersonatedUserName");
		await signOut();
	}

	useEffect(() => {});

	return (
		<Dropdown>
			<MenuButton variant="plain" className="profileMenu">
				<Box className="profileWrapper">
					<Typography className="text" textColor="white" sx={{ marginRight: "0.5em" }}>
						{impersonatedUserName ? impersonatedUserName + "*" : user.user?.name}
					</Typography>
					<Avatar
						className="profile"
						variant={props.variant ?? "soft"}
						color={impersonatedUserName ? "secondary" : "primary"}
					/>
				</Box>
			</MenuButton>
			<Menu>
				<MenuItem>
					<Link to="/minha-area" className="topMenuLink">
						<Apps color="primary" /> Minha Área
					</Link>
				</MenuItem>
				{admin && (
					<MenuItem>
						<Link to="/impersonate" className="topMenuLink">
							<Person color="secondary" /> Impersonate
						</Link>
					</MenuItem>
				)}
				{admin && (
					<MenuItem>
						<Link to="/produtos" className="topMenuLink">
							<Person color="secondary" /> Produtos
						</Link>
					</MenuItem>
				)}
				<MenuItem>
					<Link to="/colecao" className="topMenuLink">
						<LibraryBooks color="primary" /> Minha Coleção
					</Link>
				</MenuItem>
				<MenuItem>
					<Link to="/profile" className="topMenuLink">
						<Person color="primary" /> Meu Perfil
					</Link>
				</MenuItem>
				<MenuItem>
					<p onClick={handleSignOut} className="topMenuLink">
						<Logout color="primary" /> Sair
					</p>
				</MenuItem>
			</Menu>
		</Dropdown>
	);
}
