import { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { Button, Divider, Input, Stack, Typography } from "@mui/joy";
import PageHeader from "../../components/global_components/PageHeader";
import { useNavigate } from "react-router-dom";
import { editProduct, getAllProducts } from "../../services/productService";
import { fetchAuthSession } from "aws-amplify/auth";
import { AuthSession } from "@aws-amplify/core/dist/esm/singleton/Auth/types";
import Product from "../../interfaces/productInterface";
import { ModalAlert, ModalBig } from "../../components/global_components/Modal";

export default function Produtos() {
	const nav = useNavigate();
	const [productList, setProductList] = useState<Product[]>([]);
	const [editItem, setEditItem] = useState<Product>(null);
	const [updateFail, setUpdateFail] = useState(false);

	useEffect(() => {
		fetchAuthSession()
			.then((x: AuthSession) => {
				const token = decodeToken(x.tokens.accessToken.toString());
				if (!token["cognito:groups"].includes("admins")) {
					nav("/");
				}
				getAllProducts()
					.then((products: Product[]) => {
						products.sort((a, b) => a.name.localeCompare(b.name));
						setProductList(products);
					})
					.catch((err) => console.error("Erro ao consultar usuários:", err));
			})
			.catch((err) => {
				console.error("Erro ao recuperar token", err);
			});
	}, [nav]);

	const handleBlur = (e) => {
		const normalized = e.target.value.replace(",", ".");
		const parsed = parseFloat(normalized);

		if (!isNaN(parsed)) {
			setEditItem({ ...editItem, price: Math.round(parsed * 100) });
		} else {
			setEditItem({ ...editItem, price: 0 });
		}
	};

	const handleChange = (e) => {
		const inputValue = e.target.value;
		const normalized = inputValue.replace(",", ".");

		if (!isNaN(parseFloat(normalized)) || inputValue === "") {
			setEditItem({ ...editItem, price: inputValue });
		}
	};

	const handleProductUpdate = async () => {
		try {
			await editProduct(editItem.productId, editItem);
			setProductList((prevList) =>
				prevList.map((product) => (product.productId === editItem.productId ? editItem : product)),
			);
			setEditItem(null);
		} catch (err) {
			setUpdateFail(true);
		}
	};
	return (
		<>
			<Stack
				spacing={2}
				padding={{ md: "2em", xs: "0.5em 0.5em" }}
				divider={<Divider sx={{ margin: "1em 0" }} />}
				maxWidth={1300}
				width="100vw"
			>
				<PageHeader title="Gestão de Produtos"></PageHeader>
				<Stack direction="row" justifyContent="space-between" sx={{ marginBottom: 2 }}>
					<Typography level="body-md" fontWeight="bold">
						Nome
					</Typography>
					<Typography level="body-md" fontWeight="bold">
						Categoria
					</Typography>
					<Typography level="body-md" fontWeight="bold">
						Preço
					</Typography>
					<Typography level="body-md" fontWeight="bold">
						Quantidade
					</Typography>
					<Typography level="body-md" fontWeight="bold">
						Ações
					</Typography>
				</Stack>
				{productList.map((product) => (
					<Stack direction="row" justifyContent="space-between">
						<Typography level="body-md">{product.name}</Typography>
						<Typography level="body-md">{product.category}</Typography>
						<Typography level="body-md">{(product.price / 100).toFixed(2).replace(".", ",")}</Typography>
						<Typography level="body-md">{product.availableQuantity}</Typography>
						<Button onClick={() => setEditItem(product)}>Editar</Button>
					</Stack>
				))}
			</Stack>
			<ModalBig
				onClose={() => setEditItem(null)}
				open={editItem !== null}
				modalTitle="Editar informações do produto"
				buttonTitle="Salvar"
				onBtnClick={handleProductUpdate}
			>
				<Stack direction="row" justifyContent="space-between" sx={{ marginBottom: 2 }}>
					<Typography level="body-md" fontWeight="bold">
						Nome
					</Typography>
					<Typography level="body-md" fontWeight="bold">
						Categoria
					</Typography>
					<Typography level="body-md" fontWeight="bold">
						Preço
					</Typography>
					<Typography level="body-md" fontWeight="bold">
						Quantidade
					</Typography>
				</Stack>
				<Stack direction="row" justifyContent="space-between">
					<Input
						value={editItem?.name}
						onChange={(e) => setEditItem({ ...editItem, name: e.target.value })}
						variant={"outlined"}
						color={"primary"}
					/>
					<Input
						value={editItem?.category}
						onChange={(e) => setEditItem({ ...editItem, category: e.target.value })}
						variant={"outlined"}
						color={"primary"}
					/>
					<Input
						value={
							typeof editItem?.price === "number"
								? (editItem?.price / 100).toFixed(2).replace(".", ",")
								: editItem?.price
						}
						onChange={handleChange}
						onBlur={handleBlur}
						variant="outlined"
						color="primary"
					/>
					<Input
						value={editItem?.availableQuantity || editItem?.availableQuantity === 0 ? editItem.availableQuantity : ""}
						type="number"
						onChange={(e) => {
							const value = e.target.value;
							if (value === "") {
								setEditItem({ ...editItem, availableQuantity: null });
							} else {
								setEditItem({ ...editItem, availableQuantity: Number(value) });
							}
						}}
						variant="outlined"
						color="primary"
					/>
				</Stack>
			</ModalBig>
			<ModalAlert
				openModal={updateFail}
				title="Ops!"
				content={"Não conseguimos atualizar o produto, tente novamente mais tarde."}
				confirmText="Voltar aos produtos"
				confirmTextColor="primary"
				handleCloseModal={() => setUpdateFail(false)}
				handleConfirmation={() => setUpdateFail(false)}
				handleCancelOperation={() => {
					setUpdateFail(false);
					setEditItem(null);
				}}
			/>
		</>
	);
}
