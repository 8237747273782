import { useContext, useEffect, useState } from "react";
import { template as templateInterface } from "../../../interfaces/template";
import { getAllTemplates } from "../../../services/templateService";
import { Grid, Stack, Typography } from "@mui/joy";
import CardButton from "../../global_components/CardButton";
import { TemplateContext } from "./fast_editor";

export default function ChooseTemplate() {
	const [templates, setTemplates] = useState<templateInterface[]>([]);
	const { template, setTemplate } = useContext(TemplateContext);

	useEffect(() => {
		getAllTemplates().then((x) => {
			setTemplates(x);

			if (!template) {
				setTemplate(x[0]);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Stack spacing={2}>
			<Typography level="body-md">Para começar, escolha um modelo</Typography>
			<Grid container spacing={2} columns={{ xs: 4, md: 12 }}>
				{templates.map((x) => (
					<Grid xs={4} key={x.templateId}>
						<CardButton
							key={x.templateId}
							text={x.name}
							image={x.image}
							status={x.templateId === template?.templateId}
							onChange={() => setTemplate(x)}
						/>
					</Grid>
				))}
			</Grid>
		</Stack>
	);
}
