import "./upload_wizard.scss";
import "cropperjs/dist/cropper.css";
import { Divider, Grid, Stack, Typography } from "@mui/joy";
import { Cropper, ReactCropperElement } from "react-cropper";
import { CircleOutlined, CropSquare, FavoriteBorder, SquareOutlined, StarBorder } from "@mui/icons-material";
import { ModalAlert, ModalBig, ModalLoading } from "../../../../global_components/Modal";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../../../providers/UserInfo";
import CardButton from "../../../../global_components/CardButton";
import cartoon from "../../../../../assets/cartoon.png";
import React, { useEffect, useRef, useState } from "react";
import removeBG from "../../../../../assets/removeBG.jpg";
import { Effects, ImageEffects, postImageEffect } from "../../../../../services/imageService";
import Element from "../../../../../interfaces/element";

export default function UploadWizard(props: {
	image: File | null;
	aspect?: number;
	hideFormat?: boolean;
	hideEffects?: boolean;
	handleClose: () => void;
	handleImageDone: (b64: string, format: Element["type"]) => Promise<void>;
}) {
	const [imageModal, setImageModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [imageURL, setImageURL] = useState<string>();
	const [checkCartoon, setCheckCartoon] = useState(false);
	const [checkRBG, setCheckRBG] = useState(false);
	const [format, setFormat] = useState<Element["type"]>("square");
	const [modalLogin, setModalLogin] = useState(false);
	const [modalLoginStorage, setModalLoginStorage] = useState(false);
	const user = useUser();
	const nav = useNavigate();

	const cropperRef = useRef<ReactCropperElement>(null);
	// const onCrop = () => {
	// 	const cropper = cropperRef.current?.cropper;
	// };

	useEffect(() => {
		setImageModal(props.image != null);
		if (props.image != null) {
			let url = URL.createObjectURL(props.image);
			setImageURL(url);
		}
	}, [props.image]);

	useEffect(() => {
		return () => {
			URL.revokeObjectURL(imageURL);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageModal]);

	const addEffects = async () => {
		let effectList: Effects[] = [];

		if (checkCartoon) effectList.push(Effects.CARTOONIFY);
		if (checkRBG) effectList.push(Effects.REMOVE_BACKGROUND);

		const cropper = cropperRef.current.cropper;
		const b64 = cropper.getCroppedCanvas().toDataURL();

		if (effectList.length === 0) return b64;

		if (user.user == null) {
			setModal(true);
			return;
		}

		const base64Image = b64.split(",")[1];
		const formatImage = b64.split(",")[0];

		return await postImageEffect({
			file: base64Image,
			effect: effectList,
		} as ImageEffects)
			.then((response) => {
				const finalImage = `${formatImage},${response["file"]}`;
				return finalImage;
			})
			.catch((x) => {
				console.error(x);

				throw x;
			});

		// props.handleMenuLoading([true, "Carregando imagem"]);
		// 	.finally(() => {
		// 		props.handleMenuLoading([false, "Carregando imagem"]);
		// 	});
	};

	//TODO take upload photo out and reuse the component to crop fast editor pictures

	const handlePhotoAdd = () => {
		//loading in

		//aplicar efeitos se necessário
		setLoading(true);
		addEffects()
			.then((b64: string) => {
				props
					.handleImageDone(b64, format)
					.then(() => props.handleClose())
					.catch((e) => {
						if (e instanceof DOMException && e.name === "QuotaExceededError") {
							setModalLoginStorage(true);
						}
					})
					.finally(() => setLoading(false));
			})
			.catch((err) => {
				setLoading(false);
			});

		//fazer upload da imagem

		//adicionar elemento

		//loading out
	};

	return (
		<>
			<ModalAlert
				openModal={modal}
				title="Ops!"
				content={
					"Para aplicar efeitos nas imagens você precisar entrar em sua conta.\nCadastre-se agora, ou faça login!"
				}
				cancelText="Cadastrar"
				cancelTextColor="secondary"
				confirmText="Entrar"
				confirmTextColor="primary"
				handleCloseModal={() => setModal(false)}
				handleCancelOperation={() => nav("/login")}
				handleConfirmation={() => nav("/login")}
			/>
			<ModalBig
				onClose={props.handleClose}
				open={imageModal}
				modalTitle="Editar e recortar imagem"
				buttonTitle="Adicionar"
				onBtnClick={handlePhotoAdd}
			>
				<Grid container sx={{ flexGrow: 1 }} columns={{ xs: 6, md: 12 }} maxHeight="65vh">
					<Grid xs={6} maxHeight="100%">
						{imageURL && (
							<Cropper
								src={imageURL}
								viewMode={2}
								style={{ height: "100%", width: "96%" }}
								// Cropper.js options
								dragMode="none"
								movable={false}
								zoomable={false}
								initialAspectRatio={1}
								aspectRatio={props.aspect}
								modal={true}
								guides={false}
								autoCropArea={1}
								crop={() => {}}
								ref={cropperRef}
							/>
						)}
					</Grid>
					<Grid xs={6} container spacing={2} sx={{ overflowY: "visible", maxHeight: "100%" }}>
						<Stack spacing={1}>
							{!props.hideEffects && (
								<>
									<Grid xs={12} container spacing={2}>
										<Grid xs={12}>
											<Typography level="title-md">Efeitos especiais</Typography>
										</Grid>
									</Grid>
									<Grid xs={12} container spacing={2}>
										<Grid xs={6}>
											<CardButton
												image={removeBG}
												text="Remover fundo"
												status={checkRBG}
												onChange={(status) => setCheckRBG(status)}
											/>
										</Grid>
										<Grid xs={6}>
											<CardButton
												image={cartoon}
												text="Cartoonizar"
												status={checkCartoon}
												onChange={(status) => setCheckCartoon(status)}
											/>
										</Grid>
									</Grid>
								</>
							)}
							{!props.hideFormat && (
								<>
									<Grid xs={12} container spacing={2}>
										<Grid xs={12}>
											<Divider sx={{ marginBottom: "1em" }} />
											<Typography level="title-md">Formato da imagem</Typography>
										</Grid>
									</Grid>
									<Grid xs={12} container spacing={2}>
										<Grid xs={6} md={4}>
											<CardButton
												icon={<SquareOutlined />}
												text="Padrão"
												status={format === "square"}
												onChange={(status) => setFormat("square")}
											/>
										</Grid>
										<Grid xs={6} md={4}>
											<CardButton
												icon={<CropSquare />}
												text="Arredondado"
												status={format === "roundSquare"}
												onChange={(status) => setFormat("roundSquare")}
											/>
										</Grid>
										<Grid xs={6} md={4}>
											<CardButton
												icon={<CircleOutlined />}
												text="Circulo"
												status={format === "circle"}
												onChange={(status) => setFormat("circle")}
											/>
										</Grid>
										<Grid xs={6} md={4}>
											<CardButton
												icon={<FavoriteBorder />}
												text="Coração"
												status={format === "heart"}
												onChange={(status) => setFormat("heart")}
											/>
										</Grid>
										<Grid xs={6} md={4}>
											<CardButton
												icon={<StarBorder />}
												text="Estrela"
												status={format === "star"}
												onChange={(status) => setFormat("star")}
											/>
										</Grid>
									</Grid>
								</>
							)}
						</Stack>
					</Grid>
				</Grid>
			</ModalBig>
			<ModalAlert
				openModal={modalLogin}
				title="Ops!"
				content={
					"Para aplicar efeitos nas imagens você precisar entrar em sua conta.\nCadastre-se agora, ou faça login!"
				}
				cancelText="Cadastrar"
				cancelTextColor="secondary"
				confirmText="Entrar"
				confirmTextColor="primary"
				handleCloseModal={() => setModalLogin(false)}
				handleCancelOperation={() => nav("/login")}
				handleConfirmation={() => nav("/login")}
			/>
			<ModalAlert
				openModal={modalLoginStorage}
				title="Ops!"
				content={
					"Você atingiu o armazenamento máximo de imagens de convidado.\nCadastre-se agora ou faça login para adicionar mais fotos!"
				}
				cancelText="Cadastrar"
				cancelTextColor="secondary"
				confirmText="Entrar"
				confirmTextColor="primary"
				handleCloseModal={() => setModalLogin(false)}
				handleCancelOperation={() => nav("/login")}
				handleConfirmation={() => nav("/login")}
			/>
			<ModalLoading openModal={loading} modalTitle="Aplicando efeitos e salvando imagem" />{" "}
		</>
	);
}
