import { Typography, Box } from "@mui/joy";
import Lottie from "lottie-react";
import paymentLottie from "../../lotties/payment.json";
import OrderInterface from "../../interfaces/orderInterface";
import { Wallet } from "@mercadopago/sdk-react";

export default function ProcessingComponent(props: { order: OrderInterface }) {
	return (
		<Box alignItems="center">
			<Typography level="body-md" textAlign="center">
				Clique no botão abaixo para seguir com o pagamento
			</Typography>
			<Lottie
				animationData={paymentLottie}
				width={150}
				height={150}
				style={{ paddingLeft: "2em", paddingRight: "2em" }}
			/>
			<Wallet
				initialization={{ preferenceId: props.order.preferenceId, redirectMode: "modal" }}
				locale="pt-BR"
				customization={{
					texts: { valueProp: "security_details", action: "buy", actionComplement: "brand" },
					checkout: { theme: { headerColor: "#F86F03", elementsColor: "#722974" } },
				}}
			/>
		</Box>
	);
}
