import { Box, Typography, Button } from "@mui/joy";
import { useNavigate } from "react-router-dom";

export default function EmptyCollection() {
	const nav = useNavigate();
	return (
		<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1em" }}>
			<Typography level="h2">Você ainda não tem projeto 😢</Typography>
			<Box sx={{ display: "flex", gap: "1em" }}>
				<Button color="primary" onClick={() => nav("/criar")} size="lg">
					Criar primeira caneca
				</Button>
				{/* <Button color="secondary" onClick={() => nav("/explorar")} size="lg">Explorar</Button> */}
			</Box>
		</Box>
	);
}
