import { Favorite, FavoriteBorder, MoreHoriz, ShoppingCart } from "@mui/icons-material";
import { Card, CardContent, CardCover, Dropdown, IconButton, Menu, MenuButton, MenuItem, Typography } from "@mui/joy";

import "./CardPreview.scss";
import { useRef } from "react";

export default function CardPreview(props: {
	id: string;
	width?: string;
	height?: string;
	imgSrc: string;
	showFavorite: boolean;
	favorite?: string;
	title: string;
	menu: boolean;
	thirdMenuOption?: boolean;
	firstMenuText?: string;
	secondMenuText?: string;
	thirdMenuText?: string;
	handleCardEvent?: () => void;
	handleFavoriteEvent?: (id: string) => void;
	handleFirstMenuItem?: () => void;
	handleSecondMenuItem?: () => void;
	handleThirdMenuItem?: () => void;
}) {
	const cardRef = useRef();

	function handleCardRef(evt: any) {
		if (cardRef.current === evt.target) {
			props.handleCardEvent();
		}
	}

	return (
		<Card className="cardPreview" style={{ width: props.width, height: props.height }}>
			<CardCover>
				<img src={props.imgSrc} alt="test" />
			</CardCover>
			<CardCover
				sx={{
					borderRadius: "0.75rem",
					background:
						"linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 100px)",
				}}
			/>
			<CardContent id="content" sx={{ justifyContent: "flex-end" }} onClick={(evt) => handleCardRef(evt)} ref={cardRef}>
				{props.showFavorite && (
					<IconButton className="cardFavorite" onClick={() => props.handleFavoriteEvent(props.id)} key={props.id}>
						{props.favorite === "favorite" ? (
							<Favorite sx={{ color: "var(--onPrimary)" }} />
						) : (
							<FavoriteBorder sx={{ color: "var(--onPrimary)" }} />
						)}
					</IconButton>
				)}
				<Typography level="title-lg" textColor="var(--onPrimary)">
					{props.title}
				</Typography>
				{props.menu && (
					<Dropdown>
						<MenuButton
							className="cardSubMenuIcon"
							slots={{ root: IconButton }}
							slotProps={{ root: { variant: "plain", color: "neutral" } }}
							sx={{ position: "absolute", bottom: "0.4rem", right: "0.5rem" }}
						>
							<MoreHoriz sx={{ color: "white" }} />
						</MenuButton>
						<Menu className="cardSubMenu">
							<MenuItem onClick={() => props.handleFirstMenuItem()}>{props.firstMenuText}</MenuItem>
							<MenuItem onClick={() => props.handleSecondMenuItem()}>{props.secondMenuText}</MenuItem>
							{props.thirdMenuOption && (
								<MenuItem onClick={() => props.handleThirdMenuItem()}>{props.thirdMenuText}</MenuItem>
							)}
						</Menu>
					</Dropdown>
				)}
			</CardContent>
		</Card>
	);
}
