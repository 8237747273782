import mediaType from "./mediaType";
import textType from "./textType";

export default interface Element {
	id: string;
	x?: number;
	y?: number;
	zIndex?: number;
	scaleX?: number;
	scaleY?: number;
	width?: number;
	height?: number;
	clickToBorderX?: number;
	clickToBorderY?: number;
	rotation: number;
	shown: boolean;
	shadow?: boolean;
	selected?: boolean;
	type: "text" | "star" | "hearth" | "square" | "roundSquare" | "heart" | "circle" | "sticker" | "emoji" | "background";
	media?: mediaType;
	text?: textType;
	border?: boolean;
	fill?: string;
	blocked?: boolean;
}

export const isImage = (el: Element["type"]) =>
	["star", "hearth", "square", "roundSquare", "heart", "circle"].includes(el);
