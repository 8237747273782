export const background_data = [
	"forms.png",
	"abstract.png",
	"blobs.png",
	"clouds.png",
	"curveSquare.png",
	"hexa.png",
	"paint.png",
	"pointLine.png",
];
