import { template } from "../interfaces/template";

export const getAllTemplates = async () => {
	return templateData;
};

const templateData: template[] = [
	{
		templateId: "1pic1text",
		name: "Foto e Texto",
		image: "/templates/fototexto.png",
		elements: [
			{
				id: "e252e070-c581-11ef-bb20-51cbbe63c1a4",
				media: { src: "/backgrounds/forms.png" },
				type: "background",
				x: 0,
				y: 0,
				scaleX: 1,
				scaleY: 1,
				shown: true,
				rotation: 0,
				selected: false,
			},
			{
				id: "91a13910-c581-11ef-bb20-51cbbe63c1a4",
				media: { src: "/templates/1.png" },
				type: "roundSquare",
				x: 119.4995648955771,
				y: 134.88795976185284,
				zIndex: 0,
				scaleX: 1.6314461768716289,
				scaleY: 1.6314461768716293,
				shown: true,
				rotation: 0,
				selected: false,
				width: 512,
				height: 512,
			},
			{
				id: "b93f6f50-c581-11ef-bb20-51cbbe63c1a4",
				x: 1465,
				y: 40,
				text: {
					value: "Sinta-se livre \npara se \nexpressar aqui",
					size: 150,
					align: "center",
					lineHeight: 1,
					verticalAlign: "middle",
					family: "Bhineka",
					strokeColor: "#ffffff",
					strokeSize: 10,
					padding: 2,
					color: "#000000",
				},
				selected: false,
				width: 1024,
				height: 1024,
				type: "text",
				zIndex: 2,
				scaleX: 1,
				scaleY: 1,
				shown: true,
				rotation: 0,
			},
		],
	},
	{
		templateId: "amor",
		name: "Amor, 4 fotos e palavra",
		image: "/templates/amor.png",
		elements: [
			{
				id: "e252e070-c581-11ef-bb20-51cbbe63c1a4",
				media: { src: "/backgrounds/forms.png" },
				type: "background",
				x: 0,
				y: 0,
				scaleX: 1,
				scaleY: 1,
				shown: true,
				rotation: 0,
				selected: false,
			},
			{
				id: "059233f0-c583-11ef-bb20-51cbbe63c1a4",
				media: { src: "/templates/1.png", aspect: 1 },
				type: "square",
				x: 30.990417526761536,
				y: 1.4538092414506931,
				zIndex: 0,
				scaleX: 1.0962471400100096,
				scaleY: 1.0962471400100096,
				shown: true,
				rotation: 0,
				selected: false,
				width: 512,
				height: 512,
			},
			{
				id: "0a4cfce0-c583-11ef-bb20-51cbbe63c1a4",
				media: { src: "/templates/2.png", aspect: 1 },
				type: "square",
				x: 592.953995539888,
				y: 563.4236069861086,
				zIndex: 0,
				scaleX: 1.0962471400100096,
				scaleY: 1.0962471400100096,
				shown: true,
				rotation: 0,
				selected: false,
				width: 512,
				height: 512,
			},
			{
				id: "0e809470-c583-11ef-bb20-51cbbe63c1a4",
				media: { src: "/templates/3.png", aspect: 1 },
				type: "square",
				x: 1154.9175735530132,
				y: 3.5910985458194884,
				zIndex: 0,
				scaleX: 1.0962471400100096,
				scaleY: 1.0962471400100096,
				shown: true,
				rotation: 0,
				selected: false,
				width: 512,
				height: 512,
			},
			{
				id: "0e809470-c583-11ef-bb20-51cbbe63c1a5",
				media: { src: "/templates/4.png", aspect: 1 },
				type: "square",
				x: 1704.9175735530132,
				y: 560.5910985458194884,
				zIndex: 0,
				scaleX: 1.0962471400100096,
				scaleY: 1.0962471400100096,
				shown: true,
				rotation: 0,
				selected: false,
				width: 512,
				height: 512,
			},
			{
				id: "414a7100-c583-11ef-bb20-51cbbe63c1a4",
				text: {
					value: "❤️",
					size: 64,
					align: "center",
					verticalAlign: "bottom",
					lineHeight: 1,
					color: "#000000",
					family: "EmojiMart",
					padding: -1,
				},
				height: 70,
				x: 771.50850362161,
				y: 193.98288423962333,
				type: "emoji",
				selected: false,
				scaleX: 2.192494280020019,
				scaleY: 2.192494280020019,
				shown: true,
				zIndex: 0,
				rotation: 0,
			},
			{
				id: "61ce6a80-c583-11ef-bb20-51cbbe63c1a4",
				text: {
					value: "❤️",
					size: 64,
					align: "center",
					verticalAlign: "bottom",
					lineHeight: 1,
					color: "#000000",
					family: "EmojiMart",
					padding: -1,
				},
				height: 70,
				x: 1336.8500498643944,
				y: 762.2967240451767,
				type: "emoji",
				selected: false,
				scaleX: 2.192494280020019,
				scaleY: 2.192494280020019,
				shown: true,
				zIndex: 0,
				rotation: 0,
			},
			{
				id: "1c2d9900-c584-11ef-bb20-51cbbe63c1a4",
				text: {
					value: "❤️",
					size: 64,
					align: "center",
					verticalAlign: "bottom",
					lineHeight: 1,
					color: "#000000",
					family: "EmojiMart",
					padding: -1,
				},
				height: 70,
				x: 1910.266653306978,
				y: 202.53204145709896,
				type: "emoji",
				selected: false,
				scaleX: 2.192494280020019,
				scaleY: 2.192494280020019,
				shown: true,
				zIndex: 0,
				rotation: 0,
			},
			{
				id: "6e372f40-c584-11ef-bb20-51cbbe63c1a4",
				x: 2331.50771476023,
				y: 253.49942049580625,
				text: {
					value: "AMOR",
					size: 150,
					align: "center",
					lineHeight: 1,
					verticalAlign: "bottom",
					family: "Lilita One",
					strokeColor: "#ffffff",
					strokeSize: 10,
					padding: 2,
					color: "#b71c1c",
				},
				selected: false,
				type: "text",
				zIndex: 0,
				scaleX: 1,
				scaleY: 1,
				shown: true,
				rotation: 90,
			},
		],
	},
	{
		templateId: "1front1back",
		name: "Frente e Verso",
		image: "/templates/frenteverso.png",
		elements: [
			{
				id: "e252e070-c581-11ef-bb20-51cbbe63c1a4",
				media: { src: "/backgrounds/forms.png" },
				type: "background",
				x: 0,
				y: 0,
				scaleX: 1,
				scaleY: 1,
				shown: true,
				rotation: 0,
				selected: false,
			},
			{
				id: "91a13910-c581-11ef-bb20-51cbbe63c1a4",
				media: { src: "/templates/1.png" },
				type: "roundSquare",
				x: 119.4995648955771,
				y: 140,
				zIndex: 0,
				scaleX: 1.6314461768716289,
				scaleY: 1.6314461768716293,
				shown: true,
				rotation: 0,
				selected: false,
				width: 512,
				height: 512,
			},
			{
				id: "0a4cfce0-c583-11ef-bb20-51cbbe63c1a4",
				media: { src: "/templates/2.png" },
				type: "roundSquare",
				x: 1552.2250113241482,
				y: 140,
				zIndex: 0,
				scaleX: 1.6314461768716289,
				scaleY: 1.6314461768716293,
				shown: true,
				rotation: 0,
				selected: false,
				width: 512,
				height: 512,
			},
		],
	},
	{
		templateId: "trip",
		name: "Viagem",
		image: "/templates/viagem.png",
		elements: [
			{
				id: "550e43b0-cf56-11ef-a8fc-27866024feae",
				media: { src: "#ffffff" },
				type: "background",
				x: 0,
				y: 0,
				scaleX: 1,
				scaleY: 1,
				shown: true,
				rotation: 0,
				selected: false,
			},
			{
				id: "4c728e80-c30a-11ef-84bf-7fe4ffe401c4",
				media: { src: "/templates/mar.png" },
				type: "square",
				x: 112.30623150271083,
				y: 97.96848034337822,
				zIndex: 1,
				scaleX: 1.3807744012406953,
				scaleY: 1.6136020031725056,
				shown: true,
				rotation: 0,
				selected: true,
				width: 500,
				height: 500,
			},
			{
				id: "6026ee50-c30d-11ef-84bf-7fe4ffe401c4",
				media: { src: "/templates/flor.png" },
				type: "square",
				x: 870.1173548808247,
				y: 95.5388284724816,
				zIndex: 3,
				scaleX: 1,
				scaleY: 1,
				shown: true,
				rotation: 0,
				selected: false,
				width: 800,
				height: 800,
			},
			{
				id: "47b0d5d0-c30c-11ef-84bf-7fe4ffe401c4",
				media: { src: "/templates/local.png" },
				type: "square",
				x: 99.88723015819843,
				y: 935.2956499853435,
				zIndex: 4,
				scaleX: 0.08446781937450178,
				scaleY: 0.08446781937450178,
				shown: true,
				rotation: 0,
				selected: false,
				width: 1095,
				height: 1200,
			},
			{
				id: "713d95a0-c30c-11ef-84bf-7fe4ffe401c4",
				x: 217.27923195088152,
				y: 948.8952214059976,
				text: {
					value: "Porto de Galinhas - PE",
					size: 100,
					align: "center",
					lineHeight: 1,
					verticalAlign: "bottom",
					family: "GetShow",
					strokeColor: "#ffffff",
					strokeSize: 0,
					padding: 2,
					color: "#000000",
				},
				selected: false,
				type: "text",
				zIndex: 5,
				scaleX: 1,
				scaleY: 1,
				rotation: 0,
				shown: true,
			},
			{
				id: "0340c6b0-c30e-11ef-84bf-7fe4ffe401c4",
				media: { src: "/templates/barco.png" },
				type: "square",
				x: 1737.814074489932,
				y: 95.53882847248165,
				zIndex: 5,
				scaleX: 1.0179986740738358,
				scaleY: 1,
				shown: true,
				rotation: 0,
				selected: false,
				width: 674,
				height: 800,
			},
		],
	},
	{
		templateId: "blank",
		name: "Criação livre",
		image: "/templates/blank.png",
		elements: [],
	},
];
