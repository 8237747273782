import { Box, Skeleton, Stack, Typography } from "@mui/joy";
import { useEffect } from "react";

// import InputMask from "react-input-mask";
import CardInfo from "../../global_components/CardInfo";
import { EditNote, Delete } from "@mui/icons-material";
import { useCart } from "../../../providers/cartProducts";

export default function Shipment(props: { onChange: (item) => void }) {
	const cart = useCart();
	// const [cep, setCep] = useState("");
	// const [cepDeliveryInfo, setCepDeliveryInfo] = useState("");

	const handleCheckedItem = async (itemId) => {
		const updatedShipment = cart.shipment.map((item) => ({
			...item,
			checked: item.id === itemId ? !item.checked : false,
		}));

		cart.setShipment(updatedShipment);
		props.onChange(updatedShipment.find((x) => x.id === itemId));
	};

	useEffect(() => {
		props.onChange(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cart.shipment, cart.shipmentLoading]);

	return (
		<Stack spacing={2}>
			<Typography level="body-sm">Selecione a forma de entrega</Typography>
			{cart.shipmentLoading && (
				<Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
					{cart.shipment.map((key) => (
						<Box key={key.id}>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width={"100%"}
								height="150px"
								sx={{ borderRadius: "1rem" }}
							/>
						</Box>
					))}
				</Box>
			)}
			{!cart.shipmentLoading &&
				cart.shipment.map((item) => {
					return (
						<CardInfo
							key={item.id}
							cardId={item.id}
							checked={item.checked}
							cardCheckedIcon={true}
							cardActions={false}
							isIconButton={true}
							haveSecondBtn={true}
							firstIcon={<EditNote />}
							firstIconTitle="Editar"
							firstIconColor="primary"
							firstIconTooltipColor="primary"
							firstIconTooltipVariant="outlined"
							secondIcon={<Delete />}
							secondIconTitle="Deletar"
							secondIconColor="danger"
							secondIconTooltipColor="danger"
							secondIconTooltipVariant="outlined"
							handleCheckedCard={handleCheckedItem}
						>
							<Stack direction={"row"} justifyContent={"space-between"}>
								<Stack sx={{ flexGrow: "1" }}>
									<Typography level="body-sm">{`${item.company.name} - ${item.name}`}</Typography>
									<Typography level="title-md">{`${item.delivery_range.min} a ${item.delivery_range.max} dias úteis`}</Typography>
								</Stack>
								<Typography
									sx={{ textAlign: "end" }}
									level="title-lg"
								>{`R$ ${item.price.replace(".", ",")}`}</Typography>
							</Stack>
						</CardInfo>
					);
				})}
			{/* <Box sx={{ display: "flex", gap: "1em", alignItems: "center" }}>
                <FormControl>
                    <FormLabel>Calcule o frete do seu pedido</FormLabel>
                    <InputMask mask="99999-999" value={cep} onChange={(evt) => setCep(evt.target.value)} maskChar=" ">
                        {
                            //@ts-ignore
                            () => (<Input value={cep} placeholder="00000-000" />)
                        }
                    </InputMask>
                    <FormHelperText><a className="cepLink" target="_blank" href="https://buscacepinter.correios.com.br/app/endereco/index.php" rel="noreferrer">Não sei meu CEP</a></FormHelperText>
                </FormControl>
                <Button sx={{ height: "fit-content" }} size="sm" onClick={() => setCepDeliveryInfo(cep)}>Ok</Button>
            </Box> */}
		</Stack>
	);
}
