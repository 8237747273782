import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";

const apiClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 65000,
});

apiClient.defaults.headers.post["Content-Type"] = "application/json";
apiClient.interceptors.request.use(async (req) => {
	const info = await fetchAuthSession();
	req.headers.Authorization = "Bearer " + info.tokens.accessToken.toString();
	const userId = sessionStorage.getItem("impersonatedUser") ?? info.userSub;
	req.url = req.url.replaceAll("@userID", userId);
	return req;
});

export default apiClient;
