import { background_data } from "./background_data";
import { Button, Grid } from "@mui/joy";
import CardButton from "../../../../global_components/CardButton";
import { useState } from "react";
import { ColorResult, SwatchesPicker } from "react-color";

export default function BackgroundMenu(props: { value: string; onChange: (src: string) => void }) {
	const [pickingColor, setPickingColor] = useState(false);
	return (
		<div>
			<div
				style={{
					width: "100%",
					display: "flex",
					flexWrap: "wrap",
					gap: "0.5em",
					paddingRight: "0.5em",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Button
					sx={props.value?.charAt(0) === "#" ? { backgroundColor: props.value, color: "white" } : {}}
					variant="outlined"
					color="neutral"
					fullWidth
					onClick={() => setPickingColor(true)}
				>
					Escolher cor sólida
				</Button>

				{pickingColor && (
					<div
						style={{
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: 100,
						}}
						onMouseLeave={() => setPickingColor(false)}
					>
						<SwatchesPicker
							onChangeComplete={(color: ColorResult) => {
								setPickingColor(false);
								props.onChange(color.hex);
							}}
						/>
					</div>
				)}
				<Grid container columns={{ xs: 4, md: 12 }} spacing={2}>
					{background_data.map((png, i) => {
						png = "/backgrounds/" + png;
						return (
							<Grid xs={4} key={png}>
								<CardButton key={png} image={png} status={props.value === png} onChange={() => props.onChange(png)} />
							</Grid>
						);
					})}
				</Grid>
			</div>
		</div>
	);
}
