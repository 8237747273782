import cattoMug from "../../../assets/cattoMug.png";

import {
	Select,
	Option,
	Input,
	Grid,
	Button,
	IconButton,
	Box,
	Dropdown,
	Menu,
	MenuButton,
	MenuItem,
	Stack,
	Skeleton,
	Snackbar,
} from "@mui/joy";
import { ExpandMore, Search, Add, Favorite } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";

import { Helmet } from "react-helmet";

import { getProjects, deleteProject, editProject } from "../../../services/projectService";
import collectionCard from "../../../interfaces/collectionCard";
import CardPreview from "../../../components/global_components/CardPreview";
import { ModalAlert } from "../../../components/global_components/Modal";
import "./collection.scss";

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../../providers/cartProducts";
import PageHeader from "../../../components/global_components/PageHeader";
import EmptyCollection from "../../../components/global_components/EmptyCollection";

export default function Collection({ toEdit }) {
	const [collectionData, setCollectionData] = useState<collectionCard[]>([]);
	const [favoriteFilter, setFavoriteFilter] = useState<boolean>(false);
	const [textFilter, setTextFilter] = useState("");
	const [sortFilter, setSortFilter] = useState("");
	const [isMobile, setIsMobile] = useState(false);
	const [modal, setModal] = useState(false);
	const [cartModal, setCartModal] = useState(false);
	const [snackBar, setSnackBar] = useState({ show: false, success: false });
	const [snackBarDelete, setSnackBarDelete] = useState({ show: false, success: false });
	const [snackbarProduct, setSnackbarProduct] = useState(null);
	const [remove, setRemove] = useState<collectionCard>(null);
	const [isMenuActive, setIsMenuActive] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const nav = useNavigate();
	const product = useCart();
	const myRef = useRef(null);

	const handleFavoriteEvent = async (cardID: string) => {
		const index = collectionData.findIndex((obj) => obj.projectId === cardID);
		if (collectionData[index].status === "favorite") {
			collectionData[index].status = "active";
		} else {
			collectionData[index].status = "favorite";
		}
		setCollectionData([...collectionData]);
		try {
			await editProject(collectionData[index].projectId, collectionData[index]);
		} catch (error) {
			alert("erro ao favoritar");
		}
	};

	async function handleAddToCart(project: collectionCard) {
		product
			.addToCart(project)
			.then(() => {
				setSnackBar({ show: true, success: true });
				setSnackbarProduct(project.title);
			})
			.catch(() => {
				setSnackBar({ show: true, success: false });
				setSnackbarProduct(project.title);
			});
	}

	function handleSort(sortMethod) {
		if (!sortMethod) return;
		setSortFilter(sortMethod);
		setIsMenuActive(null);
		myRef.current.scrollIntoView({ behavior: "smooth", inline: "nearest" });
	}

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768 ? true : false);
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		const handleAPI = async () => {
			setIsLoading(true);
			try {
				let projects = await getProjects();
				setIsLoading(false);
				setCollectionData(projects.filter((x: any) => !x?.projectId.startsWith("mediaIds")));
			} catch (error) {
				alert("Ocorreu um erro ao consultar os projetos");
			}
		};
		handleAPI();
	}, []);

	async function handleDeleteProject(projectId: collectionCard["projectId"]) {
		if (product.cartList.find((obj) => obj.projectId === projectId)) {
			product.removeItem(projectId);
		}
		deleteProject(projectId)
			.then(() => {
				setSnackBarDelete({ show: true, success: true });
			})
			.catch(async () => {
				setSnackBarDelete({ show: true, success: false });
				setIsLoading(true);
				let projects = await getProjects();
				setIsLoading(false);
				setCollectionData(projects.filter((x: any) => !x?.id?.startsWith("MediaIds")));
			});

		let newCollection = collectionData.filter((obj) => projectId !== obj.projectId);
		setCollectionData([...newCollection]);
	}

	function handleMenu() {
		if (!isMobile) {
			return (
				<>
					<Grid md={2}>
						<Button
							color="primary"
							fullWidth={true}
							variant={favoriteFilter ? "solid" : "outlined"}
							onClick={() => setFavoriteFilter(!favoriteFilter)}
						>
							Favoritos
						</Button>
					</Grid>
					<Grid md={2}>
						<Select
							value={sortFilter}
							onChange={(_, value) => handleSort(value)}
							color="primary"
							placeholder="Ordernar"
							indicator={<ExpandMore />}
						>
							<Option value="recent">Mais Recentes</Option>
							<Option value="old">Mais Antigo</Option>
							<Option value="az">A-Z</Option>
						</Select>
					</Grid>
					<Grid md={2.5} />
					<Grid md={1.5}>
						<Button onClick={() => nav("/criar")} color="secondary" fullWidth={true} startDecorator={<Add />}>
							Novo
						</Button>
					</Grid>
				</>
			);
		} else {
			return (
				<Grid
					container
					spacing={0}
					sx={{ position: "fixed", bottom: "0", width: "100%", backgroundColor: "#FFF6F4", zIndex: "2", height: "5em" }}
				>
					<Grid
						md={3}
						xs={3}
						sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
					>
						<IconButton
							className={`menuOption ${favoriteFilter ? "active" : ""}`}
							onClick={() => setFavoriteFilter(!favoriteFilter)}
							sx={{ color: "black" }}
						>
							<Favorite />
						</IconButton>
						<p>Favoritos</p>
					</Grid>
					<Grid
						md={3}
						xs={3}
						sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
					>
						<IconButton onClick={() => nav("/criar")} sx={{ color: "black" }}>
							<Add />
						</IconButton>
						<p>Novo</p>
					</Grid>
					<Grid
						md={3}
						xs={3}
						sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
					>
						<IconButton
							className={`menuOption ${isMenuActive === "search" ? "active" : ""}`}
							onClick={(evt) => {
								evt.preventDefault();
								myRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
								myRef.current.children[1].focus();
								setIsMenuActive(() => (isMenuActive === "search" ? null : "search"));
							}}
							sx={{ color: "black" }}
						>
							<Search />
						</IconButton>
						<p>Pesquisar</p>
					</Grid>
					<Grid
						md={3}
						xs={3}
						sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
					>
						<Dropdown>
							<MenuButton
								className={`menuOption ${isMenuActive === "sort" ? "active" : ""}`}
								onClick={() => setIsMenuActive("sort")}
								slots={{ root: IconButton }}
								slotProps={{ root: { variant: "plain", color: "neutral" } }}
							>
								<MenuIcon />
							</MenuButton>
							<Menu>
								<MenuItem onClick={() => handleSort("recent")}>Mais Recentes</MenuItem>
								<MenuItem onClick={() => handleSort("old")}>Mais Antigo</MenuItem>
								<MenuItem onClick={() => handleSort("az")}>A-Z</MenuItem>
							</Menu>
						</Dropdown>
						<p>Ordenar</p>
					</Grid>
				</Grid>
			);
		}
	}

	function cardsElement() {
		if (isLoading) {
			return (
				<Grid container spacing={2}>
					{[1, 2, 3, 4].map((key) => (
						<Grid md={3} xs={12} key={key}>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width={"100%"}
								height="300px"
								sx={{ borderRadius: "0.5rem" }}
							/>
						</Grid>
					))}
				</Grid>
			);
		}
		let sortFilterData = collectionData
			?.filter((data) => {
				if (favoriteFilter && data.status !== "favorite") {
					return false;
				}
				if (textFilter && !data.title?.toLowerCase().includes(textFilter.toLowerCase())) {
					return false;
				}
				return true;
			})
			.sort((a, b) => {
				switch (sortFilter) {
					case "old":
						if (a.createdAt > b.createdAt) return 1;
						if (a.createdAt < b.createdAt) return -1;
						if (a.createdAt === b.createdAt) return 0;
						break;

					case "recent":
						if (b.createdAt > a.createdAt) return 1;
						if (b.createdAt < a.createdAt) return -1;
						if (b.createdAt === a.createdAt) return 0;
						break;

					case "az":
						if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
						if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
						if (a.title.toLowerCase() === b.title.toLowerCase()) return 0;
						break;
				}
				return 0;
			});

		return (
			<Grid container spacing={2}>
				{sortFilterData?.map((data) => {
					return (
						<Grid md={3} xs={12} key={data.projectId}>
							<CardPreview
								height="300px"
								imgSrc={data.thumbnail ?? cattoMug}
								showFavorite={true}
								favorite={data.status}
								id={data.projectId}
								title={data.title}
								menu={true}
								firstMenuText="Editar"
								secondMenuText="Carrinho"
								thirdMenuOption={true}
								thirdMenuText="Deletar"
								handleCardEvent={() => {
									nav(`/criar/${data.projectId}`);
								}}
								handleFavoriteEvent={handleFavoriteEvent}
								handleFirstMenuItem={() => {
									nav(`/criar/${data.projectId}`);
								}}
								handleSecondMenuItem={() => {
									handleAddToCart(data);
								}}
								handleThirdMenuItem={() => {
									if (product.cartList.find((obj) => obj.projectId === data.projectId)) {
										setCartModal(true);
										setRemove(data);
									} else {
										setRemove(data);
										setModal(true);
									}
								}}
							/>
						</Grid>
					);
				})}
			</Grid>
		);
	}

	if (collectionData.length === 0 && isLoading === false) {
		return (
			<Box>
				<Helmet>
					<title>Fizz | Minha Coleção</title>
				</Helmet>
				<Stack spacing={2} padding={{ md: "2em", xs: "1em 3em" }} maxWidth={1300} width="100vw">
					<PageHeader title="Minha Coleção" />
					<EmptyCollection />
				</Stack>
			</Box>
		);
	}

	return (
		<Box>
			<Helmet>
				<title>Fizz | Minha Coleção</title>
			</Helmet>
			<Stack gap={2} padding={{ md: "2em", xs: "0.5em 0.5em" }} maxWidth={1300} width="100vw">
				<PageHeader title="Minha Coleção" />
				<Grid container spacing={2}>
					<Grid md={4} xs={12}>
						<Input
							ref={myRef}
							value={textFilter}
							onBlur={() => setIsMenuActive(null)}
							onChange={(event) => setTextFilter(event.target.value)}
							color="primary"
							variant="outlined"
							placeholder="Pesquisar..."
							startDecorator={<Search color="primary" />}
						>
							{/* <p>Pesquisar...</p> */}
						</Input>
					</Grid>

					{!isMobile && handleMenu()}
				</Grid>
				<ModalAlert
					openModal={modal}
					title="Deseja remover este projeto?"
					content={remove?.title}
					confirmTextColor="danger"
					handleConfirmation={() => {
						handleDeleteProject(remove.projectId);
						setModal(false);
					}}
					handleCancelOperation={() => setModal(false)}
					handleCloseModal={() => setModal(false)}
				/>

				<ModalAlert
					openModal={cartModal}
					title={`Deseja remover o projeto '${remove?.title}'?`}
					content="Atenção! Este projeto está adicionado ao seu carrinho, deletá-lo irá removê-lo também de seu carrinho."
					confirmTextColor="danger"
					handleConfirmation={() => {
						handleDeleteProject(remove.projectId);
						setCartModal(false);
					}}
					handleCancelOperation={() => setCartModal(false)}
					handleCloseModal={() => setCartModal(false)}
				/>

				{cardsElement()}

				<Snackbar
					autoHideDuration={3000}
					open={snackBar.show}
					variant="outlined"
					color="success"
					onClose={(event, reason) => {
						if (reason === "clickaway") {
							return;
						}
						snackBar.show = false;
						setSnackBar({ ...snackBar });
					}}
				>
					O projeto "{snackbarProduct}" foi adicionado ao carrinho!
				</Snackbar>

				<Snackbar
					autoHideDuration={3000}
					open={snackBarDelete.show}
					variant="outlined"
					color={snackBarDelete.success ? "success" : "danger"}
					onClose={(event, reason) => {
						if (reason === "clickaway") {
							return;
						}
						snackBarDelete.show = false;
						setSnackBarDelete({ ...snackBar });
					}}
				>
					{snackBarDelete.success
						? `O projeto "${remove?.title}" foi removido.`
						: `Erro ao deletar projeto "${remove?.title}", talvez haja uma compra com esse projeto em andamento.`}
				</Snackbar>
			</Stack>
			<footer>
				<Box sx={{ height: "10vh" }} />
				{isMobile && handleMenu()}
			</footer>
		</Box>
	);
}
