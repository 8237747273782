import * as React from "react";
import JoyStepper from "@mui/joy/Stepper";
import Step from "@mui/joy/Step";
import StepButton from "@mui/joy/StepButton";
import StepIndicator from "@mui/joy/StepIndicator";
import Check from "@mui/icons-material/Check";
import { Button, Stack, Typography } from "@mui/joy";
import { ReactNode } from "react";
import { ArrowBack } from "@mui/icons-material";
import AnimatedProgress from "./AnimatedProgress";

export interface step {
	name: string;
	icon?: ReactNode;
	content: ReactNode;
}

export default function Stepper(props: {
	activeStep?: number;
	onChange: (step: number) => void;
	steps: step[];
	onComplete: () => void;
	continueFixed: boolean;
}) {
	const handleStepChange = (step: number) => {
		if (step > props.steps.length) {
			props.onComplete();
		} else {
			props.onChange(step);
		}
	};

	const handleContinueButton = (children) => {
		if (props.continueFixed) {
			return (
				<Stack direction="row" spacing={1} position="fixed" bottom="5vw" width="90vw" left="5vw" zIndex="10">
					{children}
				</Stack>
			);
		} else {
			return (
				<Stack direction="row" spacing={1}>
					{children}
				</Stack>
			);
		}
	};

	return (
		<Stack spacing={2} alignContent="space-between">
			{window.innerWidth <= 768 ? (
				<Stack sx={{ width: "100%" }}>
					<AnimatedProgress percent={props.activeStep / props.steps.length} />
					<Stack direction="row" justifyContent="space-between">
						<Typography level="body-sm">{props.steps[props.activeStep - 1]?.name}</Typography>
						<Typography level="body-sm">
							{props.activeStep} / {props.steps.length}
						</Typography>
					</Stack>
				</Stack>
			) : (
				<JoyStepper sx={{ width: "100%" }}>
					{props.steps.map((step, index) => (
						<Step
							key={step.name}
							orientation="vertical"
							indicator={
								<StepIndicator
									variant={props.activeStep <= index ? "soft" : "solid"}
									color={props.activeStep < index ? "neutral" : "primary"}
								>
									{props.activeStep <= index ? (step.icon ?? index + 1) : <Check />}
								</StepIndicator>
							}
							sx={[props.activeStep > index && index !== 2 && { "&::after": { bgcolor: "primary.solidBg" } }]}
						>
							<StepButton onClick={() => handleStepChange(index)}>{step.name}</StepButton>
						</Step>
					))}
				</JoyStepper>
			)}
			<div key={props.activeStep} style={{ flexGrow: 1, marginBottom: props.continueFixed ? "3em" : "0" }}>
				{props.steps[props.activeStep - 1]?.content}
			</div>
			{handleContinueButton(
				<>
					{props.activeStep > 1 && (
						<Button color="secondary" onClick={() => handleStepChange(props.activeStep - 1)}>
							<ArrowBack />
						</Button>
					)}
					<Button
						fullWidth
						color={props.activeStep === props.steps.length ? "success" : "primary"}
						onClick={() => handleStepChange(props.activeStep + 1)}
					>
						{props.activeStep === props.steps.length ? "Finalizar" : "Continuar"}
					</Button>
				</>,
			)}
		</Stack>
	);
}
