import collectionCard from "../interfaces/collectionCard";
import apiClient from "../plugins/apiClient";
import { fetchAuthSession } from "aws-amplify/auth";
import { exportLocalStorageToApi } from "./mediaService";

export async function getProjects() {
	const info = await fetchAuthSession();
	if (info.userSub == null) {
		let local = localGetProjectByID();
		if (local) {
			return [local];
		} else {
			return [];
		}
	}
	try {
		const response = await apiClient.get(`/customer/@userID/project`);
		const data = response.data;
		return data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

export async function getProjectByID(projectID: collectionCard["projectId"]) {
	const info = await fetchAuthSession();
	if (info.userSub == null || projectID === "convidado") {
		return localGetProjectByID();
	}
	try {
		const response = await apiClient.get(`/customer/@userID/project/${projectID}`);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function newProject(project: collectionCard) {
	delete project["projectId"];
	const info = await fetchAuthSession();
	if (info.userSub == null) {
		return localNewProject(project);
	}
	try {
		const response = await apiClient.post(`/customer/@userID/project`, project);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function editProject(projectID: collectionCard["projectId"], project: collectionCard) {
	const info = await fetchAuthSession();
	if (info.userSub == null) {
		return localNewProject(project);
	}
	if (projectID === "convidado") {
		project.projectId = null;

		//TODO limpar bases64 ao enviar projeto
		const proj = await newProject(project);
		await exportLocalStorageToApi(proj.projectId);

		sessionStorage.removeItem("localProject");
		Object.keys(localStorage).forEach((x) => {
			let kSplit = x.split("|");
			if (kSplit[0] === "img") {
				localStorage.removeItem(x);
			}
		});

		return proj;
	}
	try {
		const response = await apiClient.put(`/customer/@userID/project/${projectID}`, project);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

export async function deleteProject(projectID: collectionCard["projectId"]) {
	try {
		const response = await apiClient.delete(`/customer/@userID/project/${projectID}`);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

//FUNCTION TO SAVE ON LOCALSTORAGE IN CASE USER IS NOT LOGGED IN
function localNewProject(localProject: collectionCard) {
	localProject.projectId = "convidado";
	sessionStorage.setItem("localProject", JSON.stringify(localProject));
	return localProject;
}

function localGetProjectByID() {
	let project = JSON.parse(sessionStorage.getItem("localProject") ?? "{}");
	let offlineUrls = {};
	Object.keys(localStorage).forEach((x) => {
		let kSplit = x.split("|");
		if (kSplit[0] === "img") {
			offlineUrls[kSplit[1]] = localStorage.getItem(x);
		}
	});
	project["downloadURLs"] = JSON.parse(sessionStorage.getItem("downloadURLs") ?? "{}");
	return project;
}
