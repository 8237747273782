import { FormControl, FormLabel, Grid, Textarea } from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import { TemplateContext } from "./fast_editor";
import Element from "../../../interfaces/element";
import FontSelector, { loadFonts } from "../object_menu/text_menu/font_selector/font_selector";
import { ColorResult, SwatchesPicker } from "react-color";
import { fontData } from "../object_menu/text_menu/font_selector/fontData";

export default function FillTexts() {
	const { template, setTemplate } = useContext(TemplateContext);
	const [textValue, setTextValue] = useState("");
	const [textSize, setTextSize] = useState(150);
	const [stroke, setStroke] = useState("#ffffff");
	const [strokeSize, setStrokeSize] = useState(10);
	const [textAlign, setTextAlign] = useState("center");
	const [fontFamily, setFontFamily] = useState("Lilita One");
	const [textColor, setTextColor] = useState("#000000");

	const handleTextChange = () => {
		const newElement: Element["text"] = {
			value: textValue,
			size: textSize,
			align: textAlign,
			lineHeight: 1,
			verticalAlign: "middle",
			family: fontFamily,
			strokeColor: stroke,
			strokeSize: strokeSize,
			padding: 2,
			color: textColor,
		};

		setTemplate((old) => {
			const index = old.elements.findIndex((x) => x.type === "text" && !x.blocked);
			old.elements[index].text = newElement;
			return { ...old };
		});
	};

	useEffect(() => {
		loadFonts(fontData);

		const t = template.elements.find((x) => x.type === "text" && !x.blocked);

		setTextValue(t.text.value);
		setTextAlign(t.text.align);
		setTextColor(t.text.color);
		setStroke(t.text.strokeColor);
		setStrokeSize(t.text.strokeSize);
		setFontFamily(t.text.family);
		setTextSize(t.text.size);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (textValue) handleTextChange();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [textAlign, textColor, textSize, textValue, stroke, strokeSize, fontFamily]);

	return (
		<div className="text">
			<form className="textFormWrapper" onSubmit={(evt) => handleTextChange()}>
				<Grid container spacing={2} rowSpacing={4} sx={{ flexGrow: 1 }}>
					<Grid xs={12}>
						<FormControl>
							<FormLabel>Qual texto quer adicionar a caneca?</FormLabel>
							<Textarea
								name="textValue"
								maxRows={4}
								minRows={2}
								onChange={(evt) => setTextValue(evt.target.value)}
								value={textValue}
								sx={{ fontFamily: fontFamily, color: textColor, fontSize: 24 }}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12}>
						<FormControl>
							<FormLabel>Personalize o texto com fontes e cores</FormLabel>
							<FontSelector
								onChange={(evt, value) => setFontFamily(value)}
								value={fontFamily}
								previewText={textValue}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12} flexGrow={1}>
						<div style={{ backgroundColor: textColor, borderRadius: "0.5rem" }}>
							<SwatchesPicker
								onChangeComplete={(color: ColorResult) => setTextColor(color.hex)}
								styles={{
									default: { picker: { padding: "0.5em", paddingBottom: "0", width: "100%", height: "100%" } },
								}}
							/>
						</div>
					</Grid>
				</Grid>
			</form>
		</div>
	);
}
