import { useContext, useEffect, useState } from "react";
import Element from "../../../../interfaces/element";
import { ElementsContext } from "../../../../pages/create/create";
import { v1 as uuidV1 } from "uuid";
import {
	Button,
	FormControl,
	FormLabel,
	Grid,
	IconButton,
	Slider,
	Textarea,
	ToggleButtonGroup,
	Divider,
} from "@mui/joy";
import ColorPicker from "../../../global_components/ColorPicker";
import FontSelector from "./font_selector/font_selector";
import ChangeSetSingleton from "../../texture_renderer/ChangeSetSingleton";
import { Add } from "@mui/icons-material";

export default function TextMenu(props: { textToEdit: Element | null }) {
	const { elements, setElements } = useContext(ElementsContext);
	const [textToEdit, setTextToEdit] = useState(props.textToEdit);
	const [textValue, setTextValue] = useState("");
	const [textSize, setTextSize] = useState(150);
	const [stroke, setStroke] = useState("#ffffff");
	const [strokeSize, setStrokeSize] = useState(10);
	const [textAlign, setTextAlign] = useState("center");
	const [fontFamily, setFontFamily] = useState("Lilita One");
	const [textColor, setTextColor] = useState("#000000");
	const [verticalAlign, setVerticalAlign] = useState("middle");

	useEffect(() => {
		if (textToEdit) {
			const t = textToEdit;
			setTextValue(t.text.value);
			setTextAlign(t.text.align);
			setTextColor(t.text.color);
			setStroke(t.text.strokeColor);
			setStrokeSize(t.text.strokeSize);
			setFontFamily(t.text.family);
			setTextSize(t.text.size);
			setVerticalAlign(t.text.verticalAlign);
		} else {
			setTextValue("");
			setTextAlign("center");
			setTextColor("#000000");
			setStroke("#ffffff");
			setStrokeSize(10);
			setFontFamily("Lilita One");
			setTextSize(150);
			setVerticalAlign("middle");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (textValue) handleTextChange();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [textAlign, textColor, textSize, textValue, stroke, strokeSize, fontFamily]);

	const handleTextChange = (evt?: any, add: boolean = false) => {
		if (evt) evt.preventDefault();

		const newElement: Element["text"] = {
			value: textValue,
			size: textSize,
			align: textAlign,
			lineHeight: 1,
			verticalAlign: verticalAlign,
			family: fontFamily,
			strokeColor: stroke,
			strokeSize: strokeSize,
			padding: 2,
			color: textColor,
		};

		if (!textToEdit && add) {
			addNewText(newElement);
		} else if (textToEdit) {
			editText(newElement);
		}
	};

	const addNewText = (text: Element["text"]) => {
		const newEl: Element = {
			id: uuidV1(),
			x: null,
			y: null,
			text: text,
			selected: true,
			type: "text",
			zIndex: elements.length + 1,
			scaleX: 1,
			scaleY: 1,
			shown: true,
			rotation: 0,
		};

		setTextToEdit(newEl);

		setElements((els) => {
			ChangeSetSingleton.get().addHistory([newEl, ...els]);
			return [newEl, ...els];
		});
	};

	const editText = (text: Element["text"]) => {
		setElements((els) => {
			const oldIndex = els.findIndex((x) => x.id === textToEdit.id);
			els[oldIndex].text = text;

			ChangeSetSingleton.get().addHistory([...els]);
			return [...els];
		});
	};

	return (
		<div className="text">
			<form className="textFormWrapper" onSubmit={(evt) => handleTextChange(evt, true)}>
				<Grid container spacing={2} rowSpacing={4} sx={{ flexGrow: 1 }}>
					<Grid xs={12}>
						<FormControl>
							<FormLabel>Conteúdo</FormLabel>
							<Textarea
								name="textValue"
								maxRows={4}
								minRows={2}
								onChange={(evt) => setTextValue(evt.target.value)}
								value={textValue}
								sx={{ fontFamily: fontFamily }}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12}>
						<Divider />
					</Grid>
					<Grid xs={7}>
						<FormControl>
							<FormLabel>Fonte</FormLabel>
							<FontSelector
								onChange={(evt, value) => setFontFamily(value)}
								value={fontFamily}
								previewText={textValue}
							/>
						</FormControl>
					</Grid>
					<Grid xs={5}>
						<FormControl>
							<FormLabel>Alinhamento</FormLabel>
							<ToggleButtonGroup
								onChange={(evt: any, newValue: string) => {
									setTextAlign(newValue);
								}}
								value={textAlign}
							>
								<IconButton value="left" sx={{ width: "33.33%" }}>
									<span className="material-symbols-rounded">format_align_left</span>
								</IconButton>
								<IconButton value="center" sx={{ width: "33.33%" }}>
									<span className="material-symbols-rounded">format_align_center</span>
								</IconButton>
								<IconButton value="right" sx={{ width: "33.33%" }}>
									<span className="material-symbols-rounded">format_align_right</span>
								</IconButton>
							</ToggleButtonGroup>
						</FormControl>
					</Grid>

					<Grid xs={8}>
						<FormControl>
							<FormLabel>Tamanho do texto</FormLabel>
							<Slider
								name="textSize"
								min={0}
								max={500}
								step={10}
								valueLabelDisplay="auto"
								defaultValue={150}
								onChange={(_, value) => setTextSize(+value)}
								value={textSize}
							/>
						</FormControl>
					</Grid>
					<Grid xs={4}>
						<FormControl>
							<FormLabel>Cor do texto</FormLabel>
							<ColorPicker key={textColor} value={textColor} onChangeComplete={(color) => setTextColor(color)} />
						</FormControl>
					</Grid>

					<Grid xs={12}>
						<Divider />
					</Grid>

					<Grid xs={8}>
						<FormControl>
							<FormLabel>Tamanho da borda</FormLabel>
							<Slider
								name="strokeSize"
								min={0}
								max={80}
								step={1}
								valueLabelDisplay="auto"
								defaultValue={0}
								onChange={(_, value) => setStrokeSize(+value)}
								value={strokeSize}
							/>
						</FormControl>
					</Grid>
					<Grid xs={4}>
						<FormControl>
							<FormLabel>Cor da borda</FormLabel>
							<ColorPicker key={stroke} value={stroke} onChangeComplete={(color) => setStroke(color)} />
						</FormControl>
					</Grid>
					{!textToEdit && (
						<Grid xs={12} sx={{ marginTop: "2em" }}>
							<Button onClick={(evt) => handleTextChange(evt, true)} fullWidth={true} startDecorator={<Add />}>
								Adicionar Texto
							</Button>
						</Grid>
					)}

					{/* <Grid xs={6}>
                        <FormControl>
                            <FormLabel>Estilo</FormLabel>
                            <ToggleButtonGroup variant="outlined" onChange={(evt: any) => { setTextStyle(evt.target.value) }} value={textStyle}>
                                <IconButton value="bold">
                                    <span className="material-symbols-rounded">
                                        format_bold
                                    </span>
                                </IconButton>
                                <IconButton value="italic">
                                    <span className="material-symbols-rounded">
                                        format_italic
                                    </span>
                                </IconButton>
                                <IconButton value="underlined">
                                    <span className="material-symbols-rounded">
                                        format_underlined
                                    </span>
                                </IconButton>
                            </ToggleButtonGroup>
                        </FormControl>
                    </Grid> */}
				</Grid>
			</form>
		</div>
	);
}
