import { Typography, Box } from "@mui/joy";
import Lottie from "lottie-react";
import OrderInterface from "../../interfaces/orderInterface";
import cancelledLottie from "../../lotties/cancelled.json";

export default function CancelledComponent(props: { order: OrderInterface }) {
	return (
		<Box alignItems="center" justifyContent="center" display="flex" flexDirection="column">
			<Lottie animationData={cancelledLottie} style={{ width: 200 }} loop={false} />
			<Typography level="title-lg" textAlign="center">
				Pedido cancelado com sucesso
			</Typography>
		</Box>
	);
}
