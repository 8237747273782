import React from "react";
import FeedbackButton from "./FeedbackButton";

export default function Layout({ children }) {
	return (
		<div>
			{children}
			<FeedbackButton />
		</div>
	);
}
