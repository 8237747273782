import { Grid, Skeleton, Snackbar } from "@mui/joy";
import "./ContactStep.scss";
import StepBody from "../StepBody";
import { useEffect, useState } from "react";
import { useUser } from "../../../providers/UserInfo";
import ProfileForm from "../../profile/general/profile";
import { editCustomer, getCustomerByID } from "../../../services/customerService";
import Customer from "../../../interfaces/customer";

export default function ContactStep(props: { handleNewContact: () => void; handleStepper: (value: number) => void }) {
	const user = useUser();

	// const [modal, setModal] = useState<boolean>(false);
	// const [modalAlert, setModalAlert] = useState<boolean>(false);

	const [snackbar, setSnackbar] = useState({ show: false, success: false });
	const [loading, setLoading] = useState<boolean>(false);
	const [contact, setContact] = useState<Customer>({
		address: {
			cep: "",
			city: "",
			neighbourhood: "",
			number: "",
			state: "",
			street: "",
		},
		email: "",
		name: "",
		phone: "",
	});

	async function handleUserInfo() {
		let userId = sessionStorage.getItem("userID");
		setLoading(true);
		let customerAPI = await getCustomerByID(userId);
		if (customerAPI) {
			setContact(customerAPI);
		}
		setLoading(false);
	}

	async function handleEditCustomer() {
		let userId = sessionStorage.getItem("userID");
		editCustomer(userId, contact)
			.then(() => {
				setSnackbar({ show: true, success: true });
			})
			.catch(() => {
				setSnackbar({ show: true, success: false });
			});

		user.setUser(contact);
	}

	useEffect(() => {
		handleUserInfo();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.user]);

	return (
		<StepBody handleStepper={props.handleStepper} step={1}>
			{loading && (
				<Grid md={12} xs={12} alignItems="center">
					<Skeleton
						animation="wave"
						variant="rectangular"
						width={"100%"}
						height={"300px"}
						sx={{ borderRadius: "1rem" }}
					/>
				</Grid>
			)}
			{!loading && (
				<Grid md={12} xs={12} alignItems="center">
					<ProfileForm
						customerInfo={contact}
						setCustomer={setContact}
						handleCancelOperation={handleUserInfo}
						handleEdit={handleEditCustomer}
					/>
				</Grid>
			)}

			<Snackbar
				open={snackbar.show}
				autoHideDuration={3000}
				variant="solid"
				color={snackbar.success ? "success" : "danger"}
				onClose={(event, reason) => {
					if (reason === "clickaway") {
						return;
					}
					snackbar.show = false;
					setSnackbar({ ...snackbar });
				}}
			>
				{snackbar.success ? "As atualizações de contato foram salvas com sucesso!" : "Erro ao salvar alterações!"}
			</Snackbar>
			{/* <Grid md={12} container>
				<Grid md={4}>
					<CardInfo
						cardId={1}
						cardTitle={contact.name}
						cardActions={false}
						isIconButton={true}
						haveSecondBtn={true}
						firstIcon={<EditNote />}
						firstIconTitle="Editar"
						firstIconColor="primary"
						firstIconTooltipColor="primary"
						firstIconTooltipVariant="outlined"
						secondIcon={<Delete />}
						secondIconTitle="Deletar"
						secondIconColor="danger"
						secondIconTooltipColor="danger"
						secondIconTooltipVariant="outlined"
						handleFirstIcon={() => setModal(true)}
						handleSecondIcon={() => setModalAlert(true)}
					>
						<Typography level="body-sm">
							<Typography level="title-md">Tel.: </Typography>
							{contact.phone}
						</Typography>
						<Typography level="body-sm">
							<Typography level="title-md">E-mail: </Typography>
							{contact.email}
						</Typography>
					</CardInfo>
				</Grid>
			</Grid> */}
			{/* <Grid md={12}>
				<Divider />
			</Grid>
			<Grid md={12} container>
				<Grid md={12}>
					<Typography level="title-md">Meu contato</Typography>
				</Grid>
				<Grid md={4} xs={8}>
					<FormControl>
						<FormLabel>Telefone</FormLabel>
						<Input color="primary" placeholder="(xx)9xxxx-xxxx" variant="outlined" value={user.user.phone}></Input>
					</FormControl>
				</Grid>
				<Grid md={8} xs={12}>
					<FormControl>
						<FormLabel>E-mail</FormLabel>
						<Input color="primary" placeholder="E-mail" variant="outlined" value={user.user.email}></Input>
					</FormControl>
				</Grid>
				<Grid md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
					<Button onClick={() => props.handleNewContact()}>Adicionar</Button>
				</Grid>
			</Grid>

			<ModalAlert
				openModal={modalAlert}
				title={contact.name}
				content="Deseja deletar esse endereço?"
				confirmText="Deletar"
				cancelText="Cancelar"
				confirmTextColor="danger"
				handleConfirmation={() => console.log("deleta")}
				handleCloseModal={() => setModalAlert(false)}
				handleCancelOperation={() => setModalAlert(false)}
			/> */}
		</StepBody>
	);
}
