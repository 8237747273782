import "./create.scss";

import { newProject, getProjects, editProject, getProjectByID } from "../../services/projectService";

import ObjectMenu from "../../components/create/object_menu/object_menu";
import MugTopLayout from "../../layout/mugTopLayout";
import { Helmet } from "react-helmet";
import collectionCard from "../../interfaces/collectionCard";

import Element, { isImage } from "../../interfaces/element";

import { CircularProgress, FormControl, FormLabel, Input, Snackbar, Stack, Textarea } from "@mui/joy";

import React, { createContext, useState, useEffect, lazy, Suspense, useRef } from "react";
import { ModalAlert, ModalBig, ModalForm, ModalLoading } from "../../components/global_components/Modal";
import { usePreviousRoute } from "../../providers/RouterTracker";
import { useNavigate, useParams } from "react-router-dom";
import { useCart } from "../../providers/cartProducts";
import { SaveOutlined } from "@mui/icons-material";
import ChangeSetSingleton from "../../components/create/texture_renderer/ChangeSetSingleton";

const Motor = lazy(() => import("../../components/create/motor/motor"));

export const ElementsContext = createContext<{
	elements: Element[];
	setElements: React.Dispatch<React.SetStateAction<Element[]>>;
}>(null);

export const ProjectContext = createContext<{
	project: collectionCard;
	setProject: React.Dispatch<React.SetStateAction<collectionCard>>;
}>(null);

export default function Create() {
	//HOOKS
	const previousRoute = usePreviousRoute();
	const nav = useNavigate();
	const { projectID } = useParams();
	const cart = useCart();

	const motorRef = useRef<any>();
	const timerRef = useRef(null);

	//STATE HOOK
	const [elements, setElements] = useState<Element[]>([]);
	const [isProjectLoading, setIsProjectLoading] = useState<boolean>(false);
	const [isMenuLoading, setIsMenuLoading] = useState<[boolean, string]>([false, "Loading File"]);
	const [tutorialIndex, setTutorialIndex] = useState(null);
	const [modal, setModal] = useState<boolean>(!projectID);
	const [modalAlert, setModalAlert] = useState<boolean>(false);
	const [fullscreen, setFullscreen] = useState<boolean>(false);
	const [prevRoute, setPrevRoute] = useState<string>();
	const [snackBar, setSnackBar] = useState({ show: false, success: false, icon: false });
	const [background, setBackground] = useState();
	const [project, setProject] = useState<collectionCard>({
		title: "",
		description: "",
	});
	const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
	const [tempTitle, setTempTitle] = useState(project?.title ?? "");
	const [tempDescription, setTempDescription] = useState(project?.description ?? "");

	//FUNCTIONS
	function handleTutorial(value: number | null) {
		if (value) {
			setTutorialIndex(tutorialIndex + value);
		} else {
			setTutorialIndex(null);
			setModal(true);
		}
	}

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768 ? true : false);
	};

	async function loadProjectFromAPI(projectId: string) {
		setIsProjectLoading(true);
		getProjectByID(projectId)
			.then((x) => {
				let preUrls = x.downloadURLs;
				let loadedElements = x.texts.map((y: string) => JSON.parse(y) as Element);
				loadedElements = loadedElements.map((y: Element) => {
					if (!y.media) return y;
					if (Object.keys(preUrls).includes(y.id.split("#")[0])) {
						y.media.src = preUrls[y.id.split("#")[0]];
						return y;
					}
					return y;
				});
				setElements((x) => loadedElements);
				ChangeSetSingleton.get().restartHistory(loadedElements);
				setProject(x);
			})
			.catch((x) => {
				console.error(x);
			})
			.finally(() => {
				setIsProjectLoading(false);
			});
	}

	async function getProjectData(): Promise<collectionCard> {
		return {
			title: project.title,
			description: project.description,
			mediaIds: elements
				.filter((x) => isImage(x.type) && !x.id.includes("#") && x.media?.src?.includes("https://fizzonline-artifacts"))
				.map((media) => media.id),
			texts: elements.map((x) => JSON.stringify(x)),
			thumbnail: await getThumbnail(),
		};
	}

	function handleAutoSaveProject() {
		if (project?.projectId) {
			getProjectData()
				.then((x) => {
					editProject(project.projectId, x)
						.then((y) => {
							setSnackBar({ show: true, success: true, icon: true });
						})
						.catch(() => setSnackBar({ show: true, success: false, icon: true }));
				})
				.catch(() => setSnackBar({ show: true, success: false, icon: true }));
		}
	}

	function debounceAutoSave(project, elements) {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		timerRef.current = setTimeout(handleAutoSaveProject, 10000);
	}

	async function handleSaveProject(templateProject?: collectionCard): Promise<collectionCard | void> {
		if (project.projectId) {
			return await handleEditProject(false, templateProject);
		}

		let sendData;
		if (templateProject) {
			sendData = templateProject;
		} else {
			sendData = await getProjectData();
		}

		await newProject(sendData)
			.then((x) => {
				project.projectId = x.projectId;
				setProject({ ...project });
				setSnackBar({
					show: true,
					success: true,
					icon: false,
				});
				nav(`/criar/${project.projectId}`, { replace: true });
			})
			.catch((err) => {
				console.error(err);
				setSnackBar({
					show: true,
					success: false,
					icon: false,
				});
			});

		return project;
	}

	function handleDownload() {
		motorRef.current.handleDownload();
	}

	function getThumbnail() {
		return motorRef?.current?.getThumbnail();
	}

	async function handleEditProject(toCart: boolean = false, templateProject?: collectionCard) {
		let sendData = templateProject ?? (await getProjectData());

		clearTimeout(timerRef.current);
		await editProject(project.projectId, sendData)
			.then((x) => {
				if (x?.projectId != null && x.projectId !== projectID) {
					project.projectId = x?.projectId;
					setProject({ ...project });
					nav(`/criar/${x?.projectId}`, { replace: true });
				}
				if (toCart) {
					setModalAlert(true);
				}
				setSnackBar({ show: true, success: true, icon: false });
			})
			.catch(() => setSnackBar({ show: true, success: false, icon: false }));
	}

	function handleRoute() {
		if (prevRoute !== "/login" && prevRoute !== undefined && prevRoute.split("/")[1] !== "criar") {
			nav(-1);
		} else {
			nav("/");
		}
	}

	/*function to handle the fullscreen behavior of the editor page;
	the async useState from react forces me to use the prevFullscreen function so I can work with the actual value of 'fullscreen'*/
	function handleFullscreen() {
		setFullscreen((prevFullscreen) => {
			if (!prevFullscreen) {
				document.documentElement.requestFullscreen();
			} else {
				document.exitFullscreen();
			}
			return !prevFullscreen;
		});
	}

	//LIFECYCLES
	//useEffect to watch the fullscreen event change

	useEffect(() => {
		function onFullscreenChange() {
			setFullscreen(Boolean(document.fullscreenElement));
		}

		setPrevRoute(previousRoute?.pathname);

		const handleAPI = async () => {
			if (!projectID) {
				let newProject = { title: "", description: "", id: undefined };
				setProject(newProject);
				setModal(true);
				let projects = await getProjects();
				if (projects?.length === 0) {
					setTutorialIndex(0);
				}
			} else {
				loadProjectFromAPI(projectID);
			}
		};

		handleAPI();

		document.addEventListener("fullscreenchange", onFullscreenChange);
		handleResize();
		setTutorialIndex(null);
		window.addEventListener("resize", handleResize);

		return () => {
			document.removeEventListener("fullscreenchange", onFullscreenChange);
			window.removeEventListener("resize", handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		debounceAutoSave(project, elements);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project, elements]);

	//FUNCTIONS TO SET ELEMENTS ON PAGE
	function handleTopLayout() {
		return <MugTopLayout width={isMobile ? "100vw" : "70vw"} />;
	}

	function handleContextMenu() {
		return (
			// <Box sx={{ width: "30vw", height: "100%", borderLeft: "solid 1px var(--outline)" }}>
			<ObjectMenu
				isFullscreen={fullscreen}
				bgImage={setBackground}
				mugTitle={project?.title}
				mugDescription={project?.description}
				projectId={project?.projectId}
				index={modal ? null : tutorialIndex}
				handleStep={handleTutorial}
				handleEditTitle={() => {
					setModal(true);
					setTempTitle(project.title);
					setTempDescription(project.description);
				}}
				handleSaveProject={handleEditProject}
				handleDownloadProject={handleDownload}
				handleMenuLoading={setIsMenuLoading}
				handleFullscreen={handleFullscreen}
			/>
			// </Box>
		);
	}

	//page view html
	return (
		<Stack id="mugEditor" className="creator" direction="row" flexWrap="wrap">
			<Helmet>
				<title>Fizz | Editor</title>
				<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
				<meta name="mobile-web-app-capable" content="yes" />
			</Helmet>
			<ProjectContext.Provider value={{ project, setProject }}>
				<ElementsContext.Provider value={{ elements, setElements }}>
					{!isMobile && handleContextMenu()}
					<div className="motor">
						<Suspense
							fallback={
								<>
									<CircularProgress sx={isMobile ? {} : { width: "70vw" }} />
									<ModalLoading openModal={true} modalTitle="Carregando editor" />
								</>
							}
						>
							<Motor
								index={tutorialIndex}
								handleStep={handleTutorial}
								mugBackgroundImage={background}
								handleSave={handleSaveProject}
								handleEdit={handleEditProject}
								handleSelection={() => {}}
								ref={motorRef}
							/>
						</Suspense>
					</div>
					{isMobile && handleContextMenu()}
					{fullscreen !== true && handleTopLayout()}
					{/* {handleFullScreenIcon()} */}

					<ModalForm
						openModal={modal}
						modalSize="lg"
						modalTitle="Personalizar"
						confirmText={project?.projectId != null ? "Salvar" : "Começar"}
						confirmTextColor="primary"
						handleCloseModal={() => setModal(false)}
						handleCancelOperation={() =>
							project?.projectId != null
								? (setModal(false), setTempTitle(project?.title), setTempDescription(project?.description))
								: handleRoute()
						}
						handleConfirmation={() => {
							project.title = tempTitle;
							project.description = tempDescription;
							setProject({ ...project });
							handleSaveProject();
							setModal(false);
						}}
					>
						<FormControl sx={{ marginBottom: "1em" }}>
							<FormLabel>{"Nome do projeto"}</FormLabel>
							<Input
								value={tempTitle}
								placeholder={"Minha Caneca"}
								onChange={(evt: any) => {
									setTempTitle(evt.target.value);
								}}
							></Input>
						</FormControl>
						<FormControl>
							<FormLabel>{"Dê uma descrição ao seu projeto. (Opcional)"}</FormLabel>
							<Textarea
								placeholder={"Descrição do projeto"}
								sx={{ width: "100%" }}
								maxRows={5}
								minRows={3}
								value={tempDescription}
								onChange={(evt) => {
									setTempDescription(evt.target.value);
								}}
							/>
						</FormControl>
					</ModalForm>
				</ElementsContext.Provider>
			</ProjectContext.Provider>

			<ModalLoading
				modalTitle={isMenuLoading[0] ? isMenuLoading[1] : "Carregando projeto"}
				openModal={isProjectLoading || isMenuLoading[0]}
			></ModalLoading>

			<ModalAlert
				openModal={modalAlert}
				title="Adicionar ao carrinho"
				content="deseja finalizar a edição e adicionar produto ao carrinho?"
				confirmText="Adicionar"
				confirmTextColor="success"
				handleConfirmation={async () => {
					cart.addToCart(project);
					let localCartProject: any = project;
					sessionStorage.setItem("cartItem", JSON.stringify(localCartProject));
					nav("/carrinho");
				}}
				handleCancelOperation={() => setModalAlert(false)}
				handleCloseModal={() => setModalAlert(false)}
			/>

			<Snackbar
				autoHideDuration={snackBar.icon ? 1000 : 3000}
				open={snackBar.show}
				variant="solid"
				size={snackBar.icon ? "sm" : "md"}
				color={snackBar.success ? "success" : "danger"}
				onClose={(event, reason) => {
					if (reason === "clickaway") {
						return;
					}
					snackBar.show = false;
					setSnackBar({ ...snackBar });
				}}
			>
				{snackBar.success && !snackBar.icon ? (
					`O projeto "${project.title}" foi salvo com sucesso!`
				) : !snackBar.success && !snackBar.icon ? (
					"Erro ao salvar projeto"
				) : snackBar.icon && snackBar.success ? (
					<SaveOutlined />
				) : (
					""
				)}
			</Snackbar>
		</Stack>
	);
}
