import RateReviewIcon from "@mui/icons-material/RateReview";
import React, { useState } from "react";
import { Button, Divider, Snackbar, Stack, Textarea, Typography } from "@mui/joy";
import apiClient from "../../plugins/apiClient";
export default function FeedbackButton() {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [feedback, setFeedback] = useState("");
	const [snackBar, setSnackBar] = useState({ show: false, success: false, icon: false });

	const handleFabClick = () => setIsModalOpen(true);
	const handleCloseModal = () => setIsModalOpen(false);
	const handleSendFeedback = () => {
		if (!feedback) {
			setSnackBar({ show: true, success: false, icon: false });
			return;
		}
		setIsModalOpen(false);
		apiClient.post(`/customer/@userID/feedback`, {
			feedback: feedback,
			route: window.location.pathname,
		});
		setSnackBar({ show: true, success: true, icon: false });
		console.log("Feedback enviado:", feedback);
		setFeedback("");
	};

	return (
		<>
			<Button
				variant="solid"
				color="primary"
				sx={{
					position: "fixed",
					bottom: 16,
					right: 16,
					borderRadius: "50%",
					width: 56,
					height: 56,
					boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
					zIndex: 1000,
				}}
				onClick={handleFabClick}
			>
				<RateReviewIcon />
			</Button>
			{isModalOpen && (
				<div
					style={{
						position: "fixed",
						top: "0",
						left: "0",
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						zIndex: 1000,
					}}
					onClick={handleCloseModal}
				>
					<div
						style={{
							backgroundColor: "#fff",
							padding: "20px",
							borderRadius: "8px",
							position: "relative",
						}}
						onClick={(e) => e.stopPropagation()}
					>
						<Typography level="h4" gutterBottom>
							Como podemos melhorar?
						</Typography>
						<Textarea
							name="feedback"
							maxRows={6}
							minRows={2}
							onChange={(evt) => setFeedback(evt.target.value)}
							value={feedback}
							sx={{ fontFamily: "sans-serif", color: "black", fontSize: 24 }}
							placeholder="Digite aqui sugestões, críticas ou elogios."
							style={{ width: "100%", marginTop: "16px" }}
						/>
						<Divider sx={{ my: 1 }} />
						<Stack direction="row" spacing={1}>
							<Button onClick={handleSendFeedback}>Enviar</Button>
							<Button variant="outlined" onClick={handleCloseModal}>
								Fechar
							</Button>
						</Stack>
					</div>
				</div>
			)}

			<Snackbar
				autoHideDuration={snackBar.icon ? 1000 : 3000}
				open={snackBar.show}
				variant="solid"
				size={snackBar.icon ? "sm" : "md"}
				color={snackBar.success ? "success" : "danger"}
				onClose={(event, reason) => {
					if (reason === "clickaway") {
						return;
					}
					snackBar.show = false;
					setSnackBar({ ...snackBar });
				}}
			>
				{snackBar.success
					? "Recebemos seu feedback. Muito obrigado!"
					: "Aparentemente você tentou enviar um feedback vazio."}
			</Snackbar>
		</>
	);
}
