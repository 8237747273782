import { useContext } from "react";
import { TemplateContext } from "./fast_editor";
import { Grid, Stack, Typography } from "@mui/joy";
import { isImage } from "../../../interfaces/element";
import BackgroundMenu from "../object_menu/img_menu/background_menu/background_menu";
import ImageCardCrop from "../../global_components/ImageCardCrop";

export default function ChoosePhotos() {
	const { template, setTemplate } = useContext(TemplateContext);

	const images = template.elements.filter((x) => isImage(x.type) && !x.blocked);

	const handleBackground = (src: string) => {
		setTemplate((old) => {
			const index = old.elements.findIndex((x) => x.type === "background");
			old.elements[index].media.src = src;
			return { ...old };
		});
	};

	const replaceImage = (id: string, base64: string) => {
		setTemplate((old) => {
			const index = old.elements.findIndex((x) => x.id === id);
			old.elements[index].media.src = base64;
			return { ...old };
		});
	};

	return (
		<Stack spacing={2}>
			<Typography level="body-md">
				O modelo escolhido precisa de {images.length} {images.length === 1 ? "foto" : "fotos"}, selecione do seu
				dispositivo
			</Typography>
			<Grid container spacing={2} columns={{ xs: 6, md: 18 }}>
				{images.map((x, i) => (
					<Grid xs={3} key={x.id}>
						<ImageCardCrop
							onChange={(b64: string) => replaceImage(x.id, b64)}
							src={x.media.src}
							aspect={x.media.aspect}
						/>
					</Grid>
				))}
			</Grid>
			<Typography level="body-md">Escolha agora uma cor ou arte de fundo para sua caneca</Typography>
			<Grid container spacing={2} columns={{ xs: 6, md: 12 }}>
				<BackgroundMenu
					value={template.elements.find((x) => x.type === "background")?.media?.src}
					onChange={handleBackground}
				/>
			</Grid>
		</Stack>
	);
}
