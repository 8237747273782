import { Box, Card, Divider, Stack, Typography } from "@mui/joy";
import OrderInterface from "../../interfaces/orderInterface";
import { Circle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "./OrderItemPreview.scss";

function formatDateToDDMMYYYY(isoDate) {
	const date = new Date(isoDate);

	const day = String(date.getDate()).padStart(2, "0"); // Ensure day is two digits
	const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-based month, so add 1
	const year = date.getFullYear();

	return `${day}/${month}/${year}`;
}

export default function OrderItemPreview(props: { order: OrderInterface }) {
	const nav = useNavigate();
	const statusStyle = (status: OrderInterface["orderStatus"]) => {
		let color;
		let text;
		switch (status) {
			case "processing":
				color = "var(--accent)";
				text = "Aguardando pagamento";
				break;
			case "cancelled":
				color = "red";
				text = "Cancelado";
				break;
			case "payment_received":
				color = "darkgreen";
				text = "Pagamento aprovado";
				break;
			case "in_production":
				color = "var(--primary)";
				text = "Em produção";
				break;
			case "shipping":
				color = "blue";
				text = "Em transporte";
				break;
			case "delivered":
				color = "green";
				text = "Entregue";
				break;

			default:
				color = "gray";
				text = "Em análise";
				break;
		}

		return (
			<Stack direction="row" alignItems="center">
				<Circle sx={{ color: color, marginRight: "0.25em" }} fontSize="small" />
				<Typography level="body-sm">{text}</Typography>
			</Stack>
		);
	};
	return (
		<div
			onClick={() => nav("/pedidos/" + props.order.orderId)}
			style={{ cursor: "pointer" }}
			className="orderCardWrapper"
		>
			<Card sx={{ width: "100%" }}>
				<Stack direction={"row"} justifyContent="space-between" flexGrow={1} sx={{ width: "100%" }}>
					<Box>{statusStyle(props.order.orderStatus)}</Box>
					<Typography level="body-sm">{formatDateToDDMMYYYY(props.order.createdAt)}</Typography>
				</Stack>
				<Divider sx={{ margin: "0.25em 0 -0.5em 0" }}></Divider>
				<Stack divider={<Divider />} sx={{ width: "100%" }}>
					{props.order.orderItems.map((x) => (
						<Stack
							direction="row"
							flexGrow={1}
							key={x.projectId}
							justifyContent="space-between"
							sx={{ width: "100%" }}
							divider={<Divider />}
						>
							<Stack justifyContent="start">
								<Typography level="title-md" textAlign="start">
									{x.title}
								</Typography>
								<Typography level="body-sm" textAlign="start">
									{x.product.name}
								</Typography>
							</Stack>
							<Stack>
								<Typography level="title-md">R${(x.price / 100).toFixed(2).replace(".", ",")}</Typography>
								<Typography level="body-sm" textAlign="end">
									Qtd: {x.quantity}
								</Typography>
							</Stack>
						</Stack>
					))}

					<Stack
						direction="row"
						flexGrow={1}
						key="frete"
						justifyContent="space-between"
						sx={{ width: "100%" }}
						divider={<Divider />}
					>
						<Stack justifyContent="start">
							<Typography level="title-md" textAlign="start">
								Frete
							</Typography>
							<Typography level="body-sm" textAlign="start">
								{props.order.selectedShipment.name}
							</Typography>
						</Stack>
						<Stack>
							<Typography level="title-md">
								R${(props.order.selectedShipment.price / 100).toFixed(2).replace(".", ",")}
							</Typography>
						</Stack>
					</Stack>

					{props.order.totalDiscounts && (
						<Stack
							direction="row"
							flexGrow={1}
							key="desconto"
							justifyContent="space-between"
							sx={{ width: "100%" }}
							divider={<Divider />}
						>
							<Stack justifyContent="start">
								<Typography level="title-md" textAlign="start">
									Desconto
								</Typography>
								<Typography level="body-sm" textAlign="start">
									{props.order.couponData.couponCode}
								</Typography>
							</Stack>
							<Stack>
								<Typography level="title-md">
									R${(props.order.totalDiscounts / 100).toFixed(2).replace(".", ",")}
								</Typography>
							</Stack>
						</Stack>
					)}
				</Stack>
				<Divider sx={{ margin: "-0.5em 0 0.25em 0" }}></Divider>

				<Stack direction={"row"} justifyContent="space-between" flexGrow={1} sx={{ width: "100%" }}>
					<Typography level="body-sm">Cod. {props.order.orderId}</Typography>
					<Typography level="title-lg">
						R$
						{((props.order.totalAmount + props.order.selectedShipment.price - (props.order.totalDiscounts || 0)) / 100)
							.toFixed(2)
							.replace(".", ",")}
					</Typography>
				</Stack>
			</Card>
		</div>
	);
}
