import apiClient from "../plugins/apiClient";

import Product from "../interfaces/productInterface";

export async function getAllProducts() {
	try {
		const response = await apiClient.get(`/product`);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function getProductByID(productID: Product["productId"]) {
	try {
		const response = await apiClient.get(`/product/${productID}`);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function newProduct(product: Product) {
	try {
		const response = await apiClient.post(`/product`, product);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function editProduct(productId: Product["productId"], product: Product) {
	try {
		const response = await apiClient.put(`/product/${productId}`, product);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

export async function deleteProduct(productId: Product["productId"]) {
	try {
		const response = await apiClient.delete(`/product/${productId}`);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}
