import Element from "../../../interfaces/element";

export default class ChangeSetSingleton {
	private static instance: ChangeSetSingleton | null = null;
	private changeSet: Element[][] = [];
	private currIndex: number = 0;

	public restartHistory(elementList: Element[]) {
		this.changeSet = [[...elementList]];
	}

	public addHistory(elementList: Element[]) {
		//corta o elementos desfeitos após alteração
		if (this.currIndex < this.changeSet.length - 1) {
			this.changeSet = this.changeSet.slice(0, this.currIndex + 1);
			this.currIndex = this.changeSet.length - 1;
		}

		const notProud = JSON.parse(JSON.stringify(elementList));
		this.changeSet.push(notProud);

		this.currIndex++;
	}

	public shiftIndex(amount: number) {
		if (this.currIndex + amount > this.changeSet.length - 1) return null;
		if (this.currIndex + amount < 0) return null;

		this.currIndex = this.currIndex + amount;

		return [...this.changeSet[this.currIndex]];
	}

	public static get(): ChangeSetSingleton {
		if (this.instance === null) {
			this.instance = new ChangeSetSingleton();
		}
		return this.instance;
	}
}
