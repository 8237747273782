export default function AnimatedProgress(props: { percent: number }) {
	return (
		<div
			style={{
				borderRadius: "1em",
				height: "0.75em",
				width: "100%",
				backgroundColor: "var(--outline)",
			}}
		>
			<div
				style={{
					transition: "0.5s",
					transitionTimingFunction: "ease-out",
					borderRadius: "1em",
					height: "0.75em",
					width: props.percent * 100 + "%",
					backgroundColor: "var(--primary)",
				}}
			></div>
		</div>
	);
}
