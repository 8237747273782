import { fetchAuthSession } from "aws-amplify/auth";
import { Media } from "../interfaces/mediaInterface";
import apiClient from "../plugins/apiClient";
import { MediaData } from "../interfaces/mediaDataInterface";
import axios from "axios";

export async function newMedia(projectId: string, media: Media) {
	const info = await fetchAuthSession();
	if (info.userSub == null) {
		return localMediaPost(projectId, media);
	}
	try {
		const mediaType = media.mediaType;
		const mediaFormat = media.mediaFormat;
		const mediaData: MediaData = { mediaId: media.mediaId, mediaFormat: mediaFormat, mediaType: mediaType };
		const response = await apiClient.post(`/customer/@userID/project/${projectId}/media`, mediaData);
		const uploadUrl = response.data.uploadUrl;
		const downloadUrl = response.data.downloadUrl;

		const contentType = `${mediaType}/${mediaFormat}`;
		const file = new File([b64toBlob(media.media)], "x", { type: contentType });
		await axios.put(uploadUrl, file, {
			headers: { "Content-Type": contentType },
		});
		return { preSignedUrl: downloadUrl };
	} catch (error) {
		console.error(error);
		return {};
	}
}

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
};

export function getLocalStorageUsage() {
	return new Blob(Object.values(localStorage)).size / 1024 / 1024;
}

function localMediaPost(projectId: string, media: Media) {
	const response = {
		projectId: projectId,
		preSignedUrl: {},
	};

	const b64 = `data:image/${media.mediaFormat};base64,${media.media}`;
	localStorage.setItem("img|" + media.mediaId, b64);
	response.preSignedUrl[media.mediaId] = b64;
	return response;
}

export async function exportLocalStorageToApi(projectId: string) {
	await Promise.all(
		Object.keys(localStorage).map(async (x) => {
			let kSplit = x.split("|");
			if (kSplit[0] !== "img") {
				return;
			}

			const b64 = localStorage.getItem(x);
			const base64Image = b64.split(",")[1];
			const formatImage = b64.split(",")[0];
			const imgType = formatImage.split("/")[1].split(";")[0];

			const media: Media = {
				mediaId: kSplit[1],
				mediaFormat: imgType as Media["mediaFormat"],
				mediaType: "image",
				media: base64Image,
				metadata: "{}",
			};

			return newMedia(projectId, media);
		}),
	);
}
