import "./StepBody.scss";
import { useNavigate } from "react-router-dom";

import {
	Box,
	Button,
	Card,
	Divider,
	Grid,
	IconButton,
	Input,
	Skeleton,
	Snackbar,
	Tooltip,
	Typography,
	Stack,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useUser } from "../../providers/UserInfo";
import { useCart } from "../../providers/cartProducts";
import { Add, EditNote } from "@mui/icons-material";
import Customer from "../../interfaces/customer";
import { editCustomer, validateCoupon } from "../../services/customerService";
import Shipment from "./shipment/Shipment";
import { ShipmentInterface } from "../../interfaces/shipmentInterface";
import { ModalForm } from "../global_components/Modal";
import AddressForm from "../profile/address/address";
import { getShipment } from "../../services/shipmentService";

export default function StepBody(props: {
	productValue?: number;
	totalAmount?: number;
	isLoading?: boolean;
	step: number;
	children: any;
	handleStepper: (value: number | null) => void;
	handleFinalization?: () => void;
}) {
	const nav = useNavigate();
	const user = useUser();
	const cart = useCart();
	const [productValue, setproductValue] = useState<number>(props.productValue ?? 0);
	const [shipment, setShipment] = useState<ShipmentInterface>(null);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [customer, setCustomer] = useState<Customer>({
		email: user.user?.email,
		name: user.user?.name,
		phone: user.user?.phone,
		address: user.user?.address,
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [addressModal, setAddressModal] = useState<boolean>(false);
	const [snackbar, setSnackbar] = useState({ show: false, success: false, message: "" });
	const [couponCode, setCouponCode] = useState<string>("");
	const [couponDiscount, setCouponDiscount] = useState<number>(0);

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768 ? true : false);
	};

	function handleShipmentChange(item: ShipmentInterface) {
		if (item?.checked) {
			setShipment(item);
		} else {
			setShipment(null);
		}
	}

	async function handleUpdateAddress() {
		let userId = sessionStorage.getItem("userID");
		setLoading(true);
		editCustomer(userId, customer)
			.then(async () => {
				setLoading(false);
				setSnackbar({ show: true, success: true, message: "Atualizações salvas com sucesso!" });
				user.setUser(customer);
				setCustomer(customer);
				cart.setShipmentLoading(true);
				let newShipment = await getShipment(userId);
				cart.setShipment(newShipment.filter((x: any) => !x.error).map((y) => ({ ...y, checked: false })));
				cart.setShipmentLoading(false);
			})
			.catch(() => {
				setSnackbar({ show: true, success: false, message: "Erro ao salvar alterações!" });
				window.location.reload();
			});
	}

	async function handleCancelOperation() {
		setCustomer({
			email: user.user.email,
			name: user.user.name,
			phone: user.user.phone,
			address: user.user.address,
		});
		setAddressModal(false);
	}

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		setproductValue(cart.cartList.reduce((agg, item) => agg + item.price * item.quantity, 0) / 100);
	}, [cart.cartList, props.productValue]);

	useEffect(() => {
		cart.shipment.map((x) => {
			if (x.checked) {
				setShipment(x);
			}
			return shipment;
		});
	}, [shipment, customer, user.user, cart.shipment]);

	const applyCoupon = async (coupon: string) => {
		const result = await validateCoupon(coupon);

		if (result.couponStatus === "valido") {
			setCouponDiscount(result.totalDiscounts / 100);
			setSnackbar({ show: true, success: true, message: "Desconto aplicado!" });
		} else {
			setCouponDiscount(0);
			setSnackbar({ show: true, success: false, message: "Cupom " + result.couponStatus });
		}
	};

	return (
		<Grid className="body" container flexGrow={"1"} justifyContent={"center"}>
			<Grid container columnSpacing={4} rowSpacing={2} width={"100%"}>
				<Grid container md={8} xs={12} alignContent={"flex-start"}>
					{props.children}
				</Grid>
				<Grid container md={4} xs={12}>
					<Grid md={1} xs={12} container justifyContent="center">
						<Divider
							orientation={isMobile ? "horizontal" : "vertical"}
							sx={isMobile ? { width: "100%", margin: "1em 0" } : { height: "100%" }}
						/>
					</Grid>
					<Grid md={11} xs={12}>
						<Box className="buttonWrapper">
							<Box>
								<Typography level="body-sm">Produtos</Typography>
								<Typography level="title-md">R$ {productValue.toFixed(2).replace(".", ",")}</Typography>
								<Divider sx={{ margin: "0.25em 0" }} />
								<Typography level="body-sm">Frete</Typography>
								<Typography level="title-md">
									{shipment?.checked && !cart.shipmentLoading
										? `R$ ${shipment?.price.replace(".", ",")}`
										: "Selecione o frete"}
								</Typography>
								<Divider sx={{ margin: "0.25em 0" }} />
								<Accordion>
									<AccordionSummary>Adicionar cupom</AccordionSummary>
									<AccordionDetails>
										<Stack direction="row" spacing={2}>
											<Input
												type="text"
												className="qtdInput"
												value={couponCode}
												onChange={(e) => setCouponCode(e.target.value)}
												onKeyDown={(e) => e.key === "Enter" && couponCode && applyCoupon(couponCode)}
												variant="outlined"
												placeholder="SEUCODIGOAQUI"
												slotProps={{ input: { style: { overflow: "visible", textOverflow: "clip" } } }}
												sx={{ fontSize: 16, paddingInline: "0", overflow: "visible" }}
											/>
											<Button
												disabled={!couponCode}
												variant="solid"
												color="success"
												onClick={() => applyCoupon(couponCode)}
											>
												Aplicar
											</Button>
										</Stack>
										<Typography level="body-sm" sx={{ marginTop: "0.5em" }}>
											Descontos
										</Typography>
										<Typography level="title-md">R$ {couponDiscount.toFixed(2).replace(".", ",")}</Typography>
									</AccordionDetails>
								</Accordion>
								<Divider sx={{ margin: "0.25em 0" }} />
								<Typography level="body-sm">Total</Typography>
								<Typography level="title-lg">
									R$ {(productValue + (+shipment?.price || 0) - couponDiscount).toFixed(2).replace(".", ",")}
								</Typography>
								<Divider sx={{ margin: "1em 0" }} />
								<Typography level="body-sm">{"Seu pedido será entregue em"}</Typography>
								{loading && (
									<Box className="addressInfo">
										<Skeleton
											animation="wave"
											variant="rectangular"
											width={"100%"}
											height="100px"
											sx={{ borderRadius: "1rem" }}
										/>
									</Box>
								)}
								{!loading && (
									<Box className="addressInfo">
										{user.user?.address ? (
											<>
												<Typography level="title-md">{`${user.user.address.street}, ${user.user.address.number}`}</Typography>
												<Tooltip title="Alterar" variant="plain" color="primary" placement="right">
													<IconButton color="primary" onClick={() => setAddressModal(true)}>
														<EditNote />
													</IconButton>
												</Tooltip>
											</>
										) : (
											<Card variant="soft" color="danger">
												{/* <Typography level="title-md">Ainda falta cadastrar seu endereço!</Typography> */}
												<Tooltip title="Cadastrar local de entrega" variant="plain" color="primary" placement="top">
													<IconButton color="primary" onClick={() => setAddressModal(true)} variant="solid">
														<Add /> Cadastrar endereço
													</IconButton>
												</Tooltip>
											</Card>
										)}
									</Box>
								)}
								<Divider sx={{ margin: "1em 0 1em 0" }} />
								<Shipment onChange={handleShipmentChange} />
							</Box>

							{user.user && (
								<>
									<Button
										onClick={() => {
											if (props.step !== 2) {
												props.handleStepper(+1);
											} else {
												props.handleFinalization();
											}
										}}
										color="primary"
										disabled={props.step === 0 && !shipment?.checked ? true : false}
									>
										{props.step !== 2
											? props.step === 0 && !user.user.address
												? "Cadastre seu endereço para continuar"
												: props.step === 0 && !shipment?.checked
													? "Selecione um frete para continuar"
													: "Avançar"
											: "Ir para pagamento"}
									</Button>
									<Button
										color="neutral"
										onClick={() => {
											if (props.step !== 0) {
												props.handleStepper(-1);
											} else {
												nav("/colecao");
											}
										}}
									>
										{props.step !== 0 ? "Voltar" : "Voltar para coleção"}
									</Button>
								</>
							)}
							{user.user == null && (
								<Button
									onClick={() => {
										nav("/login");
										let cartRoute = "carrinho";
										sessionStorage.setItem("carrinho", cartRoute);
									}}
									color="primary"
								>
									Fazer Login
								</Button>
							)}
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<ModalForm
				openModal={addressModal}
				modalSize="lg"
				modalWidth="80%"
				modalHeight="50%"
				modalMaxWidth="1200px"
				modalTitle="Novo endereço"
				confirmText="Salvar"
				handleConfirmation={() => {
					handleUpdateAddress();
					setAddressModal(false);
				}}
				handleCloseModal={() => setAddressModal(false)}
				handleCancelOperation={handleCancelOperation}
			>
				<AddressForm customer={customer} setCustomer={setCustomer} />
			</ModalForm>

			<Snackbar
				open={snackbar.show}
				autoHideDuration={3000}
				variant="solid"
				color={snackbar.success ? "success" : "danger"}
				onClose={(event, reason) => {
					if (reason === "clickaway") {
						return;
					}
					snackbar.show = false;
					setSnackbar({ ...snackbar });
				}}
			>
				{snackbar.message}
			</Snackbar>
		</Grid>
	);
}
