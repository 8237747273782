import { useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { Button, Divider, Stack, Typography } from "@mui/joy";
import PageHeader from "../../components/global_components/PageHeader";
import { useNavigate } from "react-router-dom";
import Customer from "../../interfaces/customer";
import { getCustomers } from "../../services/customerService";
import { fetchAuthSession } from "aws-amplify/auth";
import { AuthSession } from "@aws-amplify/core/dist/esm/singleton/Auth/types";

export default function Impersonate() {
	const nav = useNavigate();
	const [userList, setUserList] = useState<Customer[]>([]);
	useEffect(() => {
		fetchAuthSession()
			.then((x: AuthSession) => {
				const token = decodeToken(x.tokens.accessToken.toString());
				if (!token["cognito:groups"].includes("admins")) {
					nav("/");
				}
				getCustomers()
					.then((users: Customer[]) => {
						users.sort((a, b) => a.name.localeCompare(b.name));
						setUserList(users);
					})
					.catch((err) => console.error("Erro ao consultar usuários:", err));
			})
			.catch((err) => {
				console.error("Erro ao recuperar token", err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleImpersonate = (userId: string, userName: string) => {
		if (userId == null) {
			sessionStorage.removeItem("impersonatedUser");
			sessionStorage.removeItem("impersonatedUserName");
			window.location.href = window.location.origin;
			return;
		}

		sessionStorage.setItem("impersonatedUser", userId);
		sessionStorage.setItem("impersonatedUserName", userName);

		window.location.href = window.location.origin;
	};

	return (
		<Stack
			spacing={2}
			padding={{ md: "2em", xs: "0.5em 0.5em" }}
			divider={<Divider sx={{ margin: "1em 0" }} />}
			maxWidth={1300}
			width="100vw"
		>
			<PageHeader title="Impersonate"></PageHeader>
			{userList.map((user) => (
				<Stack direction="row" justifyContent="space-between">
					<Typography level="body-md">{user.name}</Typography>
					<Typography level="body-md">{user.email}</Typography>
					<Typography level="body-md">{user.customerId}</Typography>
					<Button onClick={() => handleImpersonate(user.customerId, user.name)}>Impersonar</Button>
				</Stack>
			))}
			<Button onClick={() => handleImpersonate(null, null)}>Remover Impersonação</Button>
		</Stack>
	);
}
