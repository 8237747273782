import { ArrowForwardIos } from "@mui/icons-material";
import { IconButton } from "@mui/joy";

import "./Slider.scss";
import { useEffect, useRef, useState } from "react";

export default function Slider(props: { children: any }) {
	const [sliderIndex, setSliderIndex] = useState(0);
	const sliderRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (sliderRef.current && sliderRef.current.children[sliderIndex]) {
			const cards = sliderRef.current.children;
			const currentCard = cards[sliderIndex] as HTMLElement;
			sliderRef.current.scrollTo(currentCard.offsetLeft - 25, 0);
		}
	}, [sliderIndex]);

	function handleSlider(delta: number) {
		let scrollPercentage =
			sliderRef.current.scrollLeft / (sliderRef.current.scrollWidth - sliderRef.current.clientWidth);
		const index = sliderIndex + delta;
		if (index < 0) {
			setSliderIndex(0);
			return;
		}
		if (index >= props.children.length || (scrollPercentage > 0.99 && delta > 0)) {
			return;
		}
		setSliderIndex(index);
	}

	return (
		<div className="sliderWrapper">
			<IconButton
				className="sliderBtn"
				variant="soft"
				color="secondary"
				sx={{ left: "-24px" }}
				onClick={() => {
					handleSlider(-1);
				}}
			>
				<ArrowForwardIos sx={{ transform: "rotate(180deg)" }} />
			</IconButton>
			<div className="slider" ref={sliderRef}>
				{props.children}
			</div>
			<IconButton
				className="sliderBtn"
				variant="soft"
				color="secondary"
				sx={{ right: "-24px" }}
				onClick={() => {
					handleSlider(+1);
				}}
			>
				<ArrowForwardIos />
			</IconButton>
		</div>
	);
}
