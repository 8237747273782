import type { WithAuthenticatorProps } from "@aws-amplify/ui-react";
import { View, Text, Heading, Button, useAuthenticator, useTheme, Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { I18n } from "aws-amplify/utils";
import "./login.scss";

import fizzLogo from "../../assets/logozz-color-white.png";

import { Divider, Stack, Typography } from "@mui/joy";

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
			userPoolId: process.env.REACT_APP_USER_POOL_ID,
			loginWith: {
				// Optional
				oauth: {
					domain: process.env.REACT_APP_USER_POOL_DOMAIN,
					scopes: ["fizzonline-public-api/user", "aws.cognito.signin.user.admin", "openid"],
					redirectSignIn: ["https://www.fizzonline.com.br/minha-area", "http://localhost:3000/minha-area"],
					redirectSignOut: ["https://www.fizzonline.com.br/", "http://localhost:3000/"],
					responseType: "code",
				},
				username: true,
			},
		},
	},
});

I18n.setLanguage("pt");
const dict = {
	pt: {
		"Sign In": "Entrar",
		"Sign in": "Entrar",
		"Sign Up": "Registrar-se",
		"Create Account": "Criar Conta",
		"Sign In with Google": "Entrar com o Google",
		"Sign Up with Google": "Entrar com o Google",
		or: "ou",
		"Your passwords must match": "As senhas devem ser a mesma",
		"Password must have at least 8 characters": "A senha deve ter pelo menos 8 caracteres",
		"Back to Sign In": "Voltar para o Login",
		"Send code": "Enviar código",
		"Password did not conform with policy: Password must have uppercase characters":
			"A senha não está nos conformes das políticas de segurança: A senha deve ter pelo menos uma letra maiúscula",
		"Password did not conform with policy: Password must have lowercase characters":
			"A senha não está nos conformes das políticas de segurança: A senha deve ter pelo menos uma letra minúscula",
		"Password did not conform with policy: Password must have numeric characters":
			"A senha não está nos conformes das políticas de segurança: A senha deve ter pelo menos um número",
		"Password did not conform with policy: Password must have symbol characters":
			"A senha não está nos conformes das políticas de segurança: A senha deve ter pelo menos um símbolo",
		"Username should be an email.": "E-mail inválido",
		"Confirmation Code": "Código de confirmação",
		"Enter your code": "Insira seu código",
		Confirm: "Confirmar",
		Confirming: "Confirmando",
		"Signing in": "Entrando",
		Sending: "Enviando",
		"New Password": "Nova senha",
		Submit: "Enviar",
		"User already exists": "E-mail já cadastrado",
		"Incorrect username or password.": "Usuário ou Senha incorretos",
		"Resend Code": "Reenviar código",
		"Your code is on the way. To log in, enter the code we emailed to":
			"Seu código está a caminho. Para entrar, digite o código que enviamos para o e-mail",
		"It may take a minute to arrive": "isso pode levar alguns minutos",
	},
};

I18n.putVocabularies(dict);

const components = {
	Header() {
		const { tokens } = useTheme();

		return (
			<View textAlign="center" padding={tokens.space.large}>
				<Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
					<img alt="Fizz logo" src={fizzLogo} style={{ height: "42px" }} />
					<Divider orientation="vertical" sx={{ margin: "0 1em" }} />
					<Typography>Conta Fizz Online</Typography>
				</Stack>
			</View>
		);
	},

	Footer() {
		const { tokens } = useTheme();

		return (
			<View textAlign="center" padding={tokens.space.large}>
				<Text color={tokens.colors.neutral[80]}>&copy; Todos os direitos reservados</Text>
			</View>
		);
	},

	SignIn: {
		Header() {
			const { tokens } = useTheme();

			return <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}></Heading>;
		},
		Footer() {
			const { toForgotPassword } = useAuthenticator();

			return (
				<View textAlign="center">
					<Button fontWeight="normal" onClick={toForgotPassword} size="small" variation="link">
						Esqueceu a senha?
					</Button>
				</View>
			);
		},
	},

	SignUp: {
		Header() {
			const { tokens } = useTheme();

			return <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}></Heading>;
		},
		Footer() {
			const { toSignIn } = useAuthenticator();

			return (
				<View textAlign="center">
					<Button fontWeight="normal" onClick={toSignIn} size="small" variation="link">
						Voltar para o login
					</Button>
				</View>
			);
		},
	},
	ConfirmSignUp: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Verificação de E-mail
				</Heading>
			);
		},
		Footer() {
			return <Text></Text>;
		},
	},
	SetupTotp: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			);
		},
		Footer() {
			return <Text></Text>;
		},
	},
	ConfirmSignIn: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			);
		},
		Footer() {
			return <Text></Text>;
		},
	},
	ForgotPassword: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Recuperação de senha
				</Heading>
			);
		},
		Footer() {
			return <Text></Text>;
		},
	},
	ConfirmResetPassword: {
		Header() {
			const { tokens } = useTheme();
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Recuperação de senha:
				</Heading>
			);
		},
		Footer() {
			return <Text></Text>;
		},
	},
};

const formFields = {
	signIn: {
		username: {
			label: "E-mail",
			placeholder: "Insira seu e-mail",
		},
		password: {
			label: "Senha",
			placeholder: "Insira sua senha",
		},
	},
	signUp: {
		name: {
			order: 1,
			label: "Nome",
			placeholder: "Nos diga seu nome",
			isRequired: true,
		},
		username: {
			order: 2,
			label: "Email",
			placeholder: "Insira seu email",
			isRequired: true,
		},
		password: {
			order: 3,
			label: "Senha",
			placeholder: "Insira sua senha",
			isRequired: true,
		},
		confirm_password: {
			order: 4,
			label: "Confirmação de senha",
			placeholder: "Insira sua senha novamente",
			isRequired: true,
		},
	},
	forceNewPassword: {
		password: {
			placeholder: "Insira sua senha",
		},
	},
	forgotPassword: {
		username: {
			label: "Email",
			placeholder: "Insira seu email",
		},
	},
	confirmResetPassword: {
		confirmation_code: {
			placeholder: "Insira o código de confirmação",
			label: "Código de confirmação",
			isRequired: false,
		},
		confirm_password: {
			label: "Confirme sua senha",
			placeholder: "Digite novamente sua senha",
		},
	},
	setupTotp: {
		QR: {
			totpIssuer: "test issuer",
			totpUsername: "amplify_qr_test_user",
		},
		confirmation_code: {
			label: "Código de confirmação",
			placeholder: "Insira seu código de confirmação",
			isRequired: false,
		},
	},
	confirmSignIn: {
		confirmation_code: {
			label: "Código de confirmação",
			placeholder: "Digite o código de verificação",
			isRequired: false,
		},
	},
};

export default function Login({ signOut, user }: WithAuthenticatorProps) {
	return (
		<Authenticator formFields={formFields} components={components} socialProviders={["google"]}>
			{({ signOut }) => {
				window.location.href = window.location.origin;
				return <Text></Text>;
			}}
		</Authenticator>
	);
}
