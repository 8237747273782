import { Home, AddBox, Apps, Person, LibraryBooks, Logout, Explore, Menu } from "@mui/icons-material";
import { Box, Avatar, Drawer, List, ListItem, ListItemButton, Divider, Typography, IconButton } from "@mui/joy";

import { signOut } from "aws-amplify/auth";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../providers/UserInfo";
import { useCart } from "../../providers/cartProducts";

import ShoppingCart from "../../components/global_components/ShoppingCart";

import "./MobileDrawerMenu.scss";

export default function MobileDrawerMenu(props: {
	color?: "primary" | "secondary";
	variant?: "solid" | "plain" | "outlined" | "soft";
}) {
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const [cartQuantity, setCartQuantity] = useState<number>(0);
	const [continueProject] = useState(JSON.parse(sessionStorage.getItem("localProject")));

	const user = useUser();
	const nav = useNavigate();
	const cart = useCart();

	useEffect(() => {
		setCartQuantity(cart.cartList.length ?? 0);
	}, [cartQuantity, cart]);

	async function handleSignOut() {
		await signOut();
	}

	const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event.type === "keydown" &&
			((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
		) {
			return;
		}

		setOpenDrawer(inOpen);
	};

	return (
		<aside>
			<Box className="mobileProfileWrapper" onClick={toggleDrawer(true)}>
				<Typography className="text" textColor="white">
					{user.user?.name}
				</Typography>
				{user.user !== null && (
					<Avatar className="profile" variant={props.variant ?? "soft"} color={props?.color ?? "neutral"} />
				)}
				{user.user === null && (
					<IconButton variant="solid" color="primary">
						<Menu />
					</IconButton>
				)}
			</Box>
			<Drawer open={openDrawer} onClose={toggleDrawer(false)} size="sm" anchor="right">
				<Box onClick={toggleDrawer(false)}>
					<List>
						{["Home", continueProject ? "Continuar projeto" : "Criar"].map((link) => (
							<ListItem key={link}>
								<ListItemButton
									onClick={() => {
										if (link.includes("Home")) {
											nav("/");
										}
										if (link.includes("Criar")) {
											nav("/criar");
										}
										if (link.includes("Continuar projeto")) {
											nav("criar/convidado");
										}
										if (link.includes("Explorar")) {
											nav("/explorar");
										}
									}}
									sx={{ flexDirection: "row-reverse", justifyContent: "flex-end" }}
								>
									{link}
									{link.includes("Home") && <Home color="primary" />}
									{link.includes("Criar") && <AddBox color="primary" />}
									{link.includes("Continuar projeto") && <AddBox color="primary" />}
									{link.includes("Explorar") && <Explore color="primary" />}
								</ListItemButton>
							</ListItem>
						))}
					</List>
					<Divider />
					{user.user === null && (
						<List>
							<ListItemButton onClick={() => nav("/login")}>Login</ListItemButton>
						</List>
					)}
					{user.user !== null && (
						<List>
							{["Minha Área", "Minha Coleção", "Meu Carrinho", "Meu Perfil", "Sair"].map((profile) => (
								<ListItem key={profile}>
									<ListItemButton
										onClick={() => {
											if (profile.includes("Perfil")) {
												nav("/profile");
											}
											if (profile.includes("Minha Coleção")) {
												nav("/colecao");
											}
											if (profile.includes("Meu Carrinho")) {
												nav("/carrinho");
											}
											if (profile.includes("Minha Área")) {
												nav("/minha-area");
											}
											if (profile.includes("Sair")) {
												handleSignOut();
											}
										}}
										sx={{ flexDirection: "row-reverse", justifyContent: "flex-end" }}
									>
										{profile}
										{profile.includes("Perfil") && <Person color="primary" />}
										{profile.includes("Minha Coleção") && <LibraryBooks color="primary" />}
										{profile.includes("Meu Carrinho") && (
											<ShoppingCart iconColor="#722974" productQuantity={cartQuantity} />
										)}
										{profile.includes("Minha Área") && <Apps color="primary" />}
										{profile.includes("Sair") && <Logout color="primary" />}
									</ListItemButton>
								</ListItem>
							))}
						</List>
					)}
				</Box>
			</Drawer>
		</aside>
	);
}
