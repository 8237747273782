import React, { useContext, useState } from "react";
import InputFileUpload from "../../../../global_components/InputFileUpload";
import { Stack } from "@mui/joy";
import UploadWizard from "../upload_wizard/upload_wizard";
import imageCompression from "browser-image-compression";
import { Media } from "../../../../../interfaces/mediaInterface";
import { v1 as uuidV1 } from "uuid";
import { newMedia } from "../../../../../services/mediaService";
import ChangeSetSingleton from "../../../texture_renderer/ChangeSetSingleton";
import { ElementsContext, ProjectContext } from "../../../../../pages/create/create";
import Element from "../../../../../interfaces/element";

export default function UploadMenu(props: { handleMenuLoading: (value: [boolean, string]) => void }) {
	const [img, setImg] = useState<File | null>(null);
	const { elements, setElements } = useContext(ElementsContext);
	const { project } = useContext(ProjectContext);

	const loadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files[0];

		if (file.size / (1024 * 1024) >= 1) {
			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
			};
			imageCompression(file, options)
				.then((result) => {
					if (result) {
						setImg(result);
					}
				})
				.catch((err) => {
					console.error("Error compressing image", err);
				});
		} else {
			setImg(file);
		}
	};

	const handleClear = () => {
		setImg(null);
	};

	const uploadPhoto = async (b64: string, format: Element["type"]) => {
		const base64Image = b64.split(",")[1];
		const formatImage = b64.split(",")[0];
		const imgType = formatImage.split("/")[1].split(";")[0];

		const media: Media = {
			mediaId: uuidV1(),
			mediaFormat: imgType as Media["mediaFormat"],
			mediaType: "image",
			media: base64Image,
			metadata: "{}",
		};

		await newMedia(project.projectId, media)
			.then((x) => {
				const url = x.preSignedUrl;

				const newElement: Element = {
					id: media.mediaId,
					media: {
						src: url,
					},
					type: format,
					x: null,
					y: null,
					zIndex: elements.length,
					scaleX: 1,
					scaleY: 1,
					shown: true,
					rotation: 0,
					selected: true,
				};

				setElements((els) => {
					ChangeSetSingleton.get().addHistory([newElement, ...els]);
					return [newElement, ...els];
				});
			})
			.catch((error) => console.error(error));
	};

	return (
		<div className="media">
			<Stack className="imgUpload" spacing={2}>
				<InputFileUpload onChange={loadPhoto} />
			</Stack>
			<UploadWizard image={img} handleClose={handleClear} handleImageDone={uploadPhoto} hideEffects />
		</div>
	);
}
