import { useNavigate } from "react-router-dom";
import FastEditor from "../../components/create/fast_editor/fast_editor";
import collectionCard from "../../interfaces/collectionCard";
import { editProject, newProject } from "../../services/projectService";
import { Divider, Stack, Typography } from "@mui/joy";
import { ModalLoading } from "../../components/global_components/Modal";
import { useState } from "react";

export default function Fast() {
	const nav = useNavigate();
	const [loading, setLoading] = useState(false);

	async function handleSaveProject(templateProject: collectionCard): Promise<collectionCard | void> {
		setLoading(true);
		if (templateProject.projectId) {
			await editProject(templateProject.projectId, templateProject);
			return templateProject;
		}
		await newProject(templateProject)
			.then((x) => {
				templateProject.projectId = x.projectId;
			})
			.catch((err) => {
				setLoading(false);
				console.error(err);
			});

		return templateProject;
	}

	return (
		<Stack spacing={2} alignItems="center" justifyContent="center" padding="1em">
			<Typography level="title-lg">Criação de caneca personalizada</Typography>
			<Divider />
			<FastEditor
				handleSaveProject={handleSaveProject}
				onClose={(projectId) => nav("/criar/" + projectId)}
				stopLoading={() => setLoading(false)}
			/>
			<ModalLoading modalTitle="Criando sua caneca, aguarde um momento" openModal={loading} />
		</Stack>
	);
}
