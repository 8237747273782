import axios from "axios";

const cepApiClient = axios.create({
	baseURL: "https://brasilapi.com.br/api/cep/v2",
	timeout: 500000,
});

cepApiClient.defaults.headers.post["Content-Type"] = "application/json";

export async function cepAutoComplete(cep: string) {
	try {
		const response = await cepApiClient.get(`/${cep}`);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}
