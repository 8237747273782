import { createContext, useEffect, useState } from "react";
import { template as templateInterface } from "../../../interfaces/template";
import Stepper, { step } from "../../global_components/Stepper";
import ChooseTemplate from "./choose_template";
import ChoosePhotos from "./choose_photos";
import FillTexts from "./fill_texts";
import { isImage } from "../../../interfaces/element";
import { newMedia } from "../../../services/mediaService";
import collectionCard from "../../../interfaces/collectionCard";
import { Media } from "../../../interfaces/mediaInterface";
import { Snackbar } from "@mui/joy";

export const TemplateContext = createContext<{
	template: templateInterface;
	setTemplate: React.Dispatch<React.SetStateAction<templateInterface>>;
}>(null);

export default function FastEditor(props: {
	handleSaveProject: (templateProject?: collectionCard) => Promise<collectionCard | void>;
	stopLoading: () => void;
	onClose: (projectId) => void;
}) {
	const allSteps = [
		{
			name: "vamos começar sua caneca!",
			content: <ChooseTemplate />,
		},
		{
			name: "escolha as imagens!",
			content: <ChoosePhotos />,
		},
		{
			name: "caneca quase pronta!",
			content: <FillTexts />,
		},
	];

	const [template, setTemplate] = useState<templateInterface>();
	const [steps, setSteps] = useState<step[]>(allSteps);
	const [mediaError, setMediaError] = useState(false);
	const [activeStep, setActiveStep] = useState<number>(1);

	const replaceElements = async () => {
		const userImages = template.elements.filter((x) => isImage(x.type) && x.media?.src?.includes("data:image"));

		let templateProject: collectionCard = {
			title: template.name,
			description: "Caneca personalizada 325ml, porcelana base branca",
			mediaIds: [],
		};

		templateProject.projectId = ((await props.handleSaveProject(templateProject)) as collectionCard).projectId;

		try {
			await Promise.all(
				userImages.map((x) => {
					const b64 = x.media.src;
					const base64Image = b64.split(",")[1];
					const formatImage = b64.split(",")[0];
					const imgType = formatImage.split("/")[1].split(";")[0];

					const media: Media = {
						mediaId: x.id,
						mediaFormat: imgType as Media["mediaFormat"],
						mediaType: "image",
						media: base64Image,
						metadata: "{}",
					};
					return newMedia(templateProject.projectId, media).then((response) => {
						const index = template.elements.findIndex((y) => y.id === x.id);
						template.elements[index].media.src = response["preSignedUrl"];
					});
				}),
			);
		} catch (err) {
			props.stopLoading();
			setMediaError(true);
			return;
		}

		templateProject.texts = template.elements.map((x) => JSON.stringify(x));
		templateProject.mediaIds = template.elements
			.filter((x) => isImage(x.type) && !x.id.includes("#") && x.media?.src?.includes("https://fizzonline-artifacts"))
			.map((media) => media.id);

		await props.handleSaveProject(templateProject);

		props.onClose(templateProject.projectId);
	};

	useEffect(() => {
		if (template && template?.elements.length === 0) {
			setSteps([
				{
					name: "Criar já sua caneca!",
					content: <ChooseTemplate />,
				},
			]);
		} else if (template && template?.elements?.findIndex((x) => x.type === "text" && !x.blocked) < 0) {
			setSteps([...allSteps.slice(0, 2)]);
		} else {
			setSteps([...allSteps]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [template]);

	return (
		<TemplateContext.Provider value={{ template, setTemplate }}>
			<Stepper
				onChange={(step) => setActiveStep(step)}
				steps={steps}
				activeStep={activeStep}
				onComplete={() => replaceElements()}
				continueFixed={true}
			/>
			<Snackbar open={mediaError} onClose={() => setMediaError(false)} autoHideDuration={3000} color="danger">
				Erro ao realizar upload de imagens, tente novamente
			</Snackbar>
		</TemplateContext.Provider>
	);
}
