import { Save, AddShoppingCart, Download, Fullscreen, Person, Shop, Shop2 } from "@mui/icons-material";
import { Box, Tooltip, IconButton, Button } from "@mui/joy";
import { fetchAuthSession } from "aws-amplify/auth";
import { forwardRef, useEffect, useState } from "react";
import { AuthSession } from "@aws-amplify/core/dist/esm/singleton/Auth/types";
import Tutorial from "../../../global_components/tutorial";
import { useUser } from "../../../../providers/UserInfo";
import { useNavigate } from "react-router-dom";

const SaveMenu = forwardRef(
	(
		props: {
			handleSaveProject: (boolean) => Promise<void>;
			handleDownloadProject: () => void;
			handleStep: (number) => void;
			handleFullscreen: () => void;
			index: number;
		},
		ref: any,
	) => {
		const [isAdmin, setIsAdmin] = useState(false);
		const user = useUser();
		const nav = useNavigate();
		useEffect(() => {
			fetchAuthSession().then((x: AuthSession) => {
				//@ts-ignore
				let isAdmin = x?.tokens?.accessToken?.payload["cognito:groups"]?.includes("admins");
				setIsAdmin(isAdmin);
			});
		}, []);
		return (
			<Tutorial
				tutorialText={
					"Quando estiver satisfeito com sua criação, pode escolher entre salvar em sua coleção ou adicioná-la direto ao carrinho."
				}
				open={props.index === 3}
				direction={window.innerWidth <= 768 ? "top" : "right"}
				handleSteps={props.handleStep}
				showNextBtn={false}
				complete={100}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						backgroundColor: "white",
						justifyContent: "space-evenly",
						alignContent: "center",
						zIndex: 10,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-evenly",
							padding: "0.5rem 0",
						}}
					>
						<Tooltip title="Salvar" color="primary" variant="outlined">
							<IconButton
								variant="plain"
								sx={{ width: "25%", height: "3em" }}
								color="primary"
								onClick={() => props.handleSaveProject(false)}
							>
								<Save />
							</IconButton>
						</Tooltip>
						{isAdmin && (
							<Tooltip title="Baixar" color="primary" variant="outlined">
								<IconButton
									variant="plain"
									sx={{ width: "25%", height: "3em" }}
									color="primary"
									onClick={() => props.handleDownloadProject()}
								>
									<Download />
								</IconButton>
							</Tooltip>
						)}
						{user.user && (
							<Tooltip title="Adicionar ao carrinho" color="primary" variant="outlined">
								<IconButton
									variant="plain"
									color="success"
									sx={{ width: "25%", height: "3em" }}
									onClick={() => props.handleSaveProject(true)}
								>
									<AddShoppingCart />
								</IconButton>
							</Tooltip>
						)}
						{!user.user && (
							<Tooltip title="Faça login para adicionar ao carrinho" color="primary" variant="outlined">
								<Button
									variant="plain"
									color="success"
									sx={{ width: "72%", height: "3.3em" }}
									onClick={async () => {
										await props.handleSaveProject(false);
										nav("/login");
									}}
								>
									<AddShoppingCart /> Acessar conta para adicionar ao carrinho
								</Button>
							</Tooltip>
						)}
						{window.innerWidth <= 768 && (
							<Tooltip title="Ir para tela cheia" color="primary" variant="outlined">
								<IconButton
									variant="plain"
									color="success"
									sx={{ width: "25%", height: "3em" }}
									onClick={() => props.handleFullscreen()}
								>
									<Fullscreen />
								</IconButton>
							</Tooltip>
						)}
					</Box>
				</Box>
			</Tutorial>
		);
	},
);

export default SaveMenu;
