import { Box, Button, LinearProgress, Stack, Tooltip, Typography } from "@mui/joy";

import "./tutorial.scss";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

export default function Tutorial(props: {
	tutorialText: string;
	open: boolean;
	children: any;
	direction?: "top" | "bottom" | "left" | "right" | null;
	showNextBtn?: boolean;
	showPreviousBtn?: boolean;
	complete?: number;
	handleSteps: (value: number | null) => void;
}) {
	const tooltipContent = () => {
		return (
			<Stack className="stepCard">
				<Typography sx={{ whiteSpace: "pre-wrap" }} level="body-md">
					{props.tutorialText}
				</Typography>
				<LinearProgress determinate value={props.complete} sx={{ width: "100%", margin: "1em 0" }} />
				<Box className="btn">
					{props.showNextBtn !== false && (
						<Button variant="plain" color="secondary" onClick={() => props.handleSteps(null)}>
							Pular Tutorial
						</Button>
					)}
					<div>&nbsp;</div>
					<div style={{ display: "flex", gap: "0.5em" }}>
						{props.showPreviousBtn !== false && (
							<Tooltip title="Passo anterior" variant="outlined" color="primary">
								<Button color="secondary" onClick={() => props.handleSteps(-1)}>
									<ArrowBack />
								</Button>
							</Tooltip>
						)}
						{props.showNextBtn !== false && (
							<Tooltip title="Próximo passo" variant="outlined" color="primary">
								<Button color="secondary" onClick={() => props.handleSteps(+1)}>
									<ArrowForward />
								</Button>
							</Tooltip>
						)}
						{props.showNextBtn === false && (
							<Button color="primary" onClick={() => props.handleSteps(null)}>
								Finalizar
							</Button>
						)}
					</div>
				</Box>
			</Stack>
		);
	};

	return (
		<Tooltip
			color="primary"
			variant="soft"
			arrow={true}
			open={props.open}
			disableHoverListener={true}
			title={tooltipContent()}
			placement={props.direction}
		>
			{props.children}
		</Tooltip>
	);
}
