import { fetchAuthSession } from "aws-amplify/auth";
import { useState } from "react";

async function getToken() {
	try {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};
		console.info(accessToken);
		console.info(accessToken?.toString());
		return accessToken?.toString();
	} catch (err) {
		console.error(err);
	}
}

function GetCurrentSession() {
	const [accessToken, setAccessToken] = useState("");

	async function handleClick() {
		const token = await getToken();
		setAccessToken(token ?? "");
		navigator.clipboard.writeText(token);
	}

	return (
		<>
			<button onClick={handleClick}>Get current token</button>
			<br />
			<p style={{ width: "70vw", wordWrap: "break-word" }}>Access Token: {accessToken}</p>
		</>
	);
}

export default GetCurrentSession;
