import React, { useContext } from "react";
import { Box, Divider, Stack, Typography } from "@mui/joy";
import BackgroundMenu from "./background_menu/background_menu";
import UploadMenu from "./upload_menu/upload_menu";
import { v1 as uuidV1 } from "uuid";
import { ElementsContext } from "../../../../pages/create/create";
import Element from "../../../../interfaces/element";
import ChangeSetSingleton from "../../texture_renderer/ChangeSetSingleton";

export default function ImageMenu(props: { handleMenuLoading: (value: [boolean, string]) => void; bgImage?: any }) {
	const { elements, setElements } = useContext(ElementsContext);

	const changeBackground = (src: string) => {
		const newElement: Element = {
			id: uuidV1(),
			media: {
				src: src,
			},
			type: "background",
			x: 0,
			y: 0,
			scaleX: 1,
			scaleY: 1,
			shown: true,
			rotation: 0,
		};

		setElements((els) => {
			ChangeSetSingleton.get().addHistory([newElement, ...els.filter((x) => x.type !== "background")]);
			return [newElement, ...els.filter((x) => x.type !== "background")];
		});
	};
	return (
		<Stack width="100%" spacing={2} overflow="auto" height="100%">
			<Typography level="title-md">Adicione suas imagens ao projeto</Typography>
			<UploadMenu handleMenuLoading={props.handleMenuLoading} />
			<Divider />
			<Typography level="title-md">Escolha um fundo para sua caneca</Typography>
			<Box height="100%" sx={{ overflow: "hidden", overflowY: "auto" }} padding="0em">
				<BackgroundMenu value={elements.find((x) => x.type === "background")?.media?.src} onChange={changeBackground} />
			</Box>
		</Stack>
	);
}
